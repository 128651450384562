/* SideBar Admin */
:root {
  --yellow-prime: #ffc917;
  --black-common: #161616;
  --greyish-black: #515151;
  --white-common: #f8ffff;
}

body,
html {
  overflow: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Poppins-Light;
  src: url(../font/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../font/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../font/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../font/Poppins-Bold.ttf);
}

@font-face {
  font-family: KIONA-REGULAR;
  src: url(../font/Kiona-Regular.ttf);
}

@font-face {
  font-family: Bebas-Bold;
  src: url(../font/BebasNeue-Bold.ttf);
}

.side-bar-main {
  background: linear-gradient(-90deg, #d89f00, #ffe268, #d89f00);
  height: 100vh;
  position: fixed;
  width: 331px;
}

.user-img {
  text-align: center;
  padding-top: 1.5rem;
}

.second-main-side {
  margin-left: auto;
  width: calc(100% - 360px);
}

.user-img h3 {
  margin: 0;
  font-family: Poppins-Regular;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}

.side-bar-logo {
  display: flex;
  justify-content: center;
}

.main-sidebar-list {
  display: flex;
  justify-content: center;
  padding: 40px 0px 0px;
  margin: 0 0 60px;
}

.side-bar-logo img {
  width: 100%;
}

.user-img img {
  width: 30%;
}

ul.side-list-flex {
  list-style: none;
}

li.flex-list-li a {
  display: flex;
  align-items: center;
  padding: 7px 5px;
  text-decoration: none;
  color: var(--greyish-black);
  transition: all 0.42s ease;
}

.activebarside {
  font-weight: 600;
}

.activebarside {
  background: linear-gradient(#515151, #727272, #515151);
  border-radius: 12px 0px 0px 12px;
  color: #fff !important;
}

.activebarside .none-img {
  filter: none !important;
  display: none;
}

.activebarside img {
  filter: brightness(5);
}

li.flex-list-li img {
  padding: 0px 22px 0px 12px;
}

.flex-list-li a {
  font-family: Poppins-Regular;
  font-size: 19px;
  color: rgb(255, 255, 255);
  transition: all 0.42s ease;
}

.flex-list-li a:hover {
  background: linear-gradient(#515151, #727272, #515151);
  border-radius: 12px 0px 0px 12px;
}

.flex-list-li a:hover img {
  filter: brightness(5);
}

.flex-list-li a:hover .none-img {
  display: none !important;
}

.flex-list-li a:hover {
  color: #fff;
}

ul.side-list-flex {
  margin-left: 50px;
}

.set-reports-box {
  min-height: 100vh !important;
}

.logout-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 40px;
  font-family: Poppins-Medium;
  font-size: 19px;
  color: var(--greyish-black);
  transform: translateX(-50%);
  text-decoration: none;
}

.brn-logo img {
  width: 100%;
}

.user-img h5 {
  font-size: 15px;
  color: var(--greyish-black);
  margin: 0.4rem 0 0 0;
  font-family: Poppins-Bold;
}

.flex-list-li {
  margin: 1.5rem 0;
}

/* SideBar Admin */

/* Dashboard right styles  */
.second-side-parent {
  margin-left: auto;
  width: calc(100% - 331px);
  background: #f1f1f1;
  min-height: 100vh;
  padding-bottom: 1%;
}

.dashboard-heading h2 {
  font-family: KIONA-REGULAR;
  font-size: 80px;
  color: var(--greyish-black);
  margin: 0;
}

.dashboard-right-header {
  padding: 1rem 0;
}

.dashboard-right-body {
  background: #fff;
  border-radius: 50px;
  min-height: 80vh;
}

.dashboard-content-parent {
  width: 90%;
  margin: 0 auto;
}

.main-page-boxes-parent {
  width: 92%;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.main-page-box {
  background: var(--yellow-prime);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 23px;
  padding: 5rem 0;
}

.main-page-box-inner h3 {
  font-size: 35px;
  margin: 1rem 0 0 0;
}

.main-page-box-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-page-box-inner h3,
.main-page-box-inner h4 {
  font-family: Poppins-Medium;
  color: var(--greyish-black);
}

.main-page-box-inner h4 {
  font-size: 70px;
  margin: 0 0 1rem 0;
}

.main-page-box-inner a {
  text-decoration: none;
  font-size: 20px;
  font-family: Bebas-Bold;
  display: inline-block;
  min-width: 191px;
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  border-radius: 10px;
  color: var(--white-common);
  background: linear-gradient(#515151, #727272, #515151);
}

.sales-anlytics img {
  width: 100%;
}

.order-list-box {
  background: var(--greyish-black);
  border-radius: 23px;
  padding: 1.5rem 0;
}

.order-list-heading h4 {
  color: var(--white-common);
  font-size: 20px;
  margin: 0;
  font-family: Poppins-Regular;
}

.order-list-box-inner {
  width: 95%;
  margin: 0 auto;
}

.order-list-heading img {
  padding-right: 0.5%;
}

.order-table-inner p {
  color: var(--black-common);
  font-size: 13px;
  font-family: Poppins-SemiBold;
  margin: 0;
}

.order-table-parent {
  background: linear-gradient(#dfa800, #ffe162, #dfa700);
  display: grid;
  gap: 2%;
  grid-template-columns: 20% 20% 15% 15% 15% 5%;
  border-radius: 10px;
  min-height: 55px;
  align-items: center;
}

.order-table-parent-rev {
  background: transparent;
  border: 1px solid var(--white-common);
  border-radius: 6px;
  transition: 0.3s ease-in-out;
}

.order-table-parent-rev:hover {
  border-color: var(--yellow-prime);
  background: var(--yellow-prime);
  transform: scale(1.04);
}

.order-table-parent-rev:hover p {
  color: var(--black-common);
}

.order-table-parent-rev:hover img {
  filter: invert(1);
}

.order-table-parent-rev p {
  color: var(--white-common);
  font-family: Poppins-Regular;
}

.order-table-sup-parent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 1rem 0;
}

.main-page-box-inner img {
  max-width: 120px;
}

.right-icons-parent {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Dashboard right styles  */

/* Product right styles  */
.dsh-board-inner-head {
  width: 95%;
  margin: 0 auto;
  padding: 2.5rem 0 2rem 0;
}

.right-icn img {
  width: 100%;
}

.add-pt-btn a {
  background: linear-gradient(#dfa800, #ffe162, #dfa700);
  color: var(--greyish-black);
}

.inner-left-head img {
  filter: invert(1);
}

.inner-left-head h4 {
  color: var(--greyish-black);
}

.inner-left-head {
  width: 100%;
}

.inner-pages-body {
  width: 95%;
  margin: 0 auto;
  padding: 2rem 0;
}

.pr-table-inner-parent th {
  background: linear-gradient(#dfa800, #ffe162, #dfa700);
  min-height: 39px;
  border-radius: 0px;
}

.pr-table p {
  margin: 0;
  font-family: Poppins-SemiBold;
  font-size: 13px;
  color: var(--black-common);
}

.text-indent-cust {
  text-indent: 8%;
}

.brdr-right-pr {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

input[type="checkbox"] {
  cursor: pointer;
}

.pr-table img {
  max-width: 50px;
  max-height: 50px;
}

.sec-prnt-tble {
  background: transparent;
  border: 1px solid #161616;
  margin: 0 0 20px;
}
.pr-table-inner-parent-rev p {
  color: var(--black-common);
  font-family: Poppins-Regular;
}

.dlt-edit a {
  padding-right: 0.5rem;
}

.line-through-text {
  text-decoration: line-through;
  color: var(--greyish-black);
}

.norm-price {
  color: var(--yellow-prime);
}

.pr-table p span {
  display: block;
}

.pr-table-parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 2rem 0;
}

.txt-right-pagi p {
  margin: 0;
  font-size: 13px;
  color: var(--greyish-black);
  font-family: Poppins-Regular;
}

.left-chevi img {
  max-width: 14px;
}

.invert-none {
  filter: invert(0) !important;
}

.pr-add-heading {
  padding: 2rem 0;
}

.text-input-pr h4 {
  font-size: 13px;
  color: var(--greyish-black);
  margin: 0;
  font-family: Poppins-SemiBold;
}

.text-input-pr h4 span {
  color: var(--yellow-prime);
}

.input-pr input,
.input-pr textarea,
.input-pr select {
  background: #e5e5e5;
  width: 100%;
  font-family: Poppins-Regular;
  font-size: 13px;
  color: var(--black-common);
  border: none;
  outline: none;
  padding: 0 1rem;
}

.input-pr input,
.input-pr select {
  min-height: 37px;
}

.input-pr textarea {
  min-height: 300px;
  resize: none;
}

.input-pr-parent {
  display: grid;
  gap: 2%;
  grid-template-columns: 18% 80%;
}

.col9inner-add-pr hr {
  opacity: 1;
  height: 1px;
  background: var(--greyish-black);
  color: var(--greyish-black);
}

.input-pr-parent-sup {
  padding: 1rem 0;
}

.input-pr-parent-pd {
  padding-bottom: 1rem;
}

.rel-pr-heading {
  text-indent: 4%;
}

/* box img css styles */
.image-box {
  width: 100%;
  min-height: 203px;
  max-height: 203px;
  border-radius: 5px;
  background-color: #e6e6e6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio and cover entire box */
}

input[type="file"] {
  display: none;
}

.image-box-heading h3 {
  font-size: 13px;
  color: var(--greyish-black);
  font-family: Poppins-SemiBold;
}

.image-box p {
  font-size: 15px;
  color: rgb(23, 46, 88, 50%);
  font-family: Poppins-SemiBold;
  margin: 0;
}

.img-plus {
  width: unset !important;
}

.image-box p span {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0.3rem 0;
}

.permanent-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  /* Maintain aspect ratio and fit the image within the box */
}

.prdt-upld-btn {
  display: flex;
  justify-content: end;
  padding: 1rem 0 0 0;
}

.prdt-upld-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 127px;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  min-height: 32px;
  border-radius: 10px;
  color: var(--greyish-black);
  font-size: 13px;
  font-family: Poppins-SemiBold;
  text-decoration: none;
}

.right-parent-sup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Customer Page styles  */
.grid-sales {
  width: 100%;
  display: grid;
  gap: 2%;
  grid-template-columns: 25% 25% 15% 15% 12%;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  border-radius: 6px;
}

.inner-grid-sales p {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--greyish-black);
  font-family: Poppins-SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
}

.grid-sales-two {
  background: transparent !important;
  border: 1px solid var(--black-common);
}

.inner-grid-sales p span {
  padding-right: 0.4rem;
}

.grid-sales-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 0 2rem 0;
}

.pd-customer-row {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.grid-sales-two {
  transition: all 0.3s ease-in-out;
}

.grid-sales-two:hover {
  background-color: var(--yellow-prime) !important;
  border-color: var(--yellow-prime) !important;
  transform: scale(1.05);
}

.inner-grid-sales p a img {
  filter: invert(1);
}

.inner-grid-sales p button img {
  filter: invert(1);
}
.inner-grid-sales p button {
  border: none;
}

.inner-grid-sales p button {
  border: none;
  background: transparent;
}

.grid-sales-two p {
  font-family: Poppins-Regular;
}

.filter-box-heading h3 {
  font-family: Poppins-Medium;
  font-size: 20px;
  margin: 0;
  color: var(--black-common);
}

.filter-box-heading h3 img {
  padding-right: 0.4rem;
}

.filter-box-heading {
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.filter-lb-inp-parent label {
  font-size: 13px;
  width: 100%;
  color: var(--white-common);
  font-family: Poppins-SemiBold;
  margin-bottom: 0.3rem;
}

.filter-lb-inp-parent input {
  font-size: 10px;
  width: 100%;
  color: #7b7a7a;
  font-family: Poppins-Regular;
  min-height: 40px;
  padding: 0 0.5rem;
  border: none;
}

.filter-box-body {
  background: var(--greyish-black);
  padding: 1rem 0;
}

.filter-lb-inp-parent {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 0.3rem;
}

.reset-filt-parent input,
.reset-filt-parent button {
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  font-family: Poppins-SemiBold;
  font-size: 13px;
  color: var(--black-common);
  border: none;
  outline: none;
  border-radius: 10px;
  min-width: 72px;
  min-height: 32px;
}

.reset-filt-parent button img {
  max-width: 12px;
  padding-right: 0.1rem;
}

.reset-filt-parent {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0 0.5rem 0;
}

.filter-box-parent {
  width: 90%;
  margin: 0 0 0 auto;
}

.date-inpt {
  background-image: url(../imgs/date-bg.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}

.customer-right-body {
  min-height: initial;
}

.customer-right-body .inner-pages-body {
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0 2rem 0;
}

.cust-det-head {
  padding-top: 1.5rem;
}

.hr-edit-cust {
  opacity: 1;
  background: rgb(81, 81, 81, 70%);
  color: rgb(81, 81, 81, 70%);
  height: 1px;
}

.edit-cust-grid {
  display: grid;
  gap: 2%;
  grid-template-columns: 15% 83%;
  align-items: center;
}

.edit-cust-grid-inner p {
  font-size: 13px;
  font-family: Poppins-SemiBold;
  margin: 0;
  color: var(--greyish-black);
}

.edit-cust-grid-inner input,
.edit-cust-grid-inner textarea {
  color: #7e7e7e;
  font-size: 13px;
  font-family: Poppins-Regular;
  border: 1px solid var(--greyish-black);
  border-radius: 7px;
  background: #e6e6e6;
  width: 100%;
  padding: 0 0.4rem;
}

.edit-cust-grid-inner input {
  min-height: 32px;
}

.edit-cust-grid-inner textarea {
  min-height: 125px;
  resize: none;
}

/* Customer Page styles  */

/* Manage Reviews Page styles  */
.grid-manage-parent {
  display: grid;
  gap: 2%;
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  align-items: center;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  border-radius: 10px;
  min-height: 55px;
}

.grid-manage-inner {
  text-align: center;
}

.grid-manage-inner p {
  font-family: Poppins-SemiBold;
  font-size: 13px;
  margin: 0;
  color: var(--black-common);
}

.grid-manage-parent-rev {
  background: transparent;
  border: 1px solid var(--greyish-black);
  transition: all 0.3s ease-in-out;
}

.grid-manage-parent-rev:hover {
  background: var(--yellow-prime);
  border-color: var(--yellow-prime);
}

.grid-manage-inner a img {
  filter: invert(1);
}

.mange-star-parent svg {
  color: #ff9b29;
  font-size: 20px;
}

.yel-circ {
  filter: none;
}

.red-circ {
  filter: hue-rotate(295deg) saturate(9.5);
}

.green-circ {
  filter: hue-rotate(45deg);
}

.grid-manage-inner p img {
  padding-right: 2%;
}

.grid-manage-parent-rev p {
  font-family: Poppins-Regular;
}

.grid-manage-parent-sup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 2rem 0 1rem 0;
}

.mnge-rev-pagi {
  padding: 1rem 0;
}

.edit-mnge-heading h3 {
  font-size: 30px;
  font-family: Poppins-SemiBold;
  margin: 0;
  color: var(--greyish-black);
}

.edit-mnge-star svg {
  font-size: 29px;
  color: #ff9b29;
}

.edit-mange-cent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 0 2rem 0;
}

.mng-reve-he {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.app-dec-btn {
  display: flex;
  justify-content: end;
  gap: 10px;
  padding-top: 2rem;
}

.app-dec-btn button {
  border: none;
  outline: none;
  font-family: Poppins-SemiBold;
  color: var(--white-common);
  font-size: 13px;
  min-width: 150px;
  min-height: 39px;
  border-radius: 10px;
}

.appr {
  background: #23a405;
}

.decl {
  background: #d10000;
}

.save-btn-add-pr a,
.save-btn-add-pr button {
  border: none;
  outline: none;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  min-width: 168px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--greyish-black);
  font-family: Poppins-SemiBold;
  font-size: 15px;
  border-radius: 10px;
}

.save-btn-add-pr {
  padding: 2rem 0 0 0;
}

/* Manage Reviews Page styles  */
/* Product right styles  */

/* Login page styles */
.login-section {
  height: 100vh;
  position: relative;
}

.login-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 65%);
}

.background-video-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.login-content-parent {
  position: relative;
  z-index: 3;
}

.login-content-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.login-box {
  background: var(--white-common);
  border-radius: 14px;
  width: 48%;
}

.login-logo img {
  width: 100%;
  max-width: 120px;
}

.login-head-para h3 {
  font-size: 34px;
  font-family: Poppins-SemiBold;
  color: var(--black-common);
}

.login-head-para p {
  font-size: 20px;
  font-family: Poppins-Regular;
  color: var(--black-common);
}

.input-login input {
  border: 1px solid #707070;
  min-height: 49px;
  width: 100%;
  font-family: Poppins-Regular;
  outline: none;
  font-size: 16px;
  color: #f4bd31;
  padding: 0 0 0 3.5rem;
}

.input-login input::placeholder {
  color: #f4bd31;
}

.remem-me span {
  font-family: Poppins-Regular;
  font-size: 18px;
  padding-left: 1%;
  color: var(--black-common);
}

.login-btn {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 0 0;
}

.login-btn a,
.login-btn button {
  display: inline-block;
  text-decoration: none;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: var(--black-common);
  background: var(--yellow-prime);
  border-radius: 19px;
  min-width: 203px;
  text-align: center;
  padding: 0.75rem 0;
  transition: 0.3s ease-in-out;
}
.login-btn button {
  border: none;
}

.login-btn a:hover,
.login-btn button:hover {
  color: var(--yellow-prime);
  background: var(--black-common);
}
.login-box-inner {
  width: 75%;
  margin: 0 auto;
  padding: 1.5rem 0 2rem 0;
}

.input-and-rem-parent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-logo {
  padding-bottom: 0.7rem;
}

.env-icon,
.lock-icon {
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
}

.remem-me {
  display: flex;
  align-items: center;
}

.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  font-size: 18px;
  color: #10101085;
}

/* Login page styles */

.right-icons-parent a,
.right-icons-parent i,
.right-icons-parent img,
.right-icons-parent button,
.dlt-edit img {
  cursor: pointer;
}

.pr-table-del-img {
  margin-right: 0.5rem;
}

/* modal styles */
.modal-close-button img {
  cursor: pointer;
}
.delete-modal-parent {
  position: relative;
  z-index: 2;
  /* background: rgb(255, 255, 255, 9%); */
  border-radius: 23px;
}
.modal-content {
  background: transparent !important;
  border: none !important;
}
.delete-modal-parent::before,
.billing-modal-parent::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 23px;
  -webkit-backdrop-filter: blur(32px) brightness(0.6);
  backdrop-filter: blur(122px) brightness(1.2);
  background: rgb(255, 255, 255, 9%);
}
.dlte-modal-txt h3 {
  font-family: Poppins-Regular;
  font-size: 32px;
  margin: 0;
  color: var(--white-common);
}
.DeleteModal .modal-dialog {
  max-width: 36%;
  align-items: center;
  display: flex;
  height: 100%;
}
.delete-modal-btn-parent {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.delete-modal-btn-parent button {
  border: none;
  outline: none;
  font-family: Poppins-SemiBold;
  font-size: 12px;
  min-width: 112px;
  min-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
.GoBack {
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  color: var(--black-common);
}
.dlt-any {
  background: #cf0b0b;
  color: var(--white-common);
}
.delete-modal-content-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 2rem 0;
}
.modal-close-button {
  position: absolute;
  top: 5%;
  right: 2%;
}
.tick-modal-parent {
  border-radius: 23px;
  background: #fff;
  padding: 2rem 0;
}

.tick-modal-parent .dlte-modal-txt h3 {
  color: var(--black-common);
}
.billing-heading h3 {
  font-family: Poppins-Regular;
  margin: 0;
  font-size: 22px;
  color: var(--white-common);
}
.billing-modal-parent hr {
  opacity: 1;
  height: 1px;
  background-color: var(--white-common);
  color: var(--white-common);
  margin: 0.7rem 0;
}
.save-btn-bill button {
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  color: var(--black-common);
}
.inner-input-bill-mdl input,
.inner-input-bill-mdl label {
  width: 100%;
}
.inner-input-bill-mdl label,
.grid-inpt-modal label {
  font-size: 14px;
  font-family: Poppins-SemiBold;
  color: var(--white-common);
  margin-bottom: 0.4rem;
}
.inner-input-bill-mdl input {
  font-size: 11px;
  font-family: Poppins-Regular;
  color: var(--greyish-black);
  min-height: 35px;
  border: none;
  outline: none;
  padding: 0 0.5rem;
}
.billing-modal-inner-parent {
  padding: 1.5rem 0;
}
.billing-heading,
.bill-modl-input-parent,
.save-btn-bill {
  width: 90%;
  margin: 0 auto;
}
.billing-modal-inner-parent .modal-close-button {
  top: 3.4%;
  right: 3%;
}
.save-btn-bill {
  padding-top: 1.5rem;
}
.DeleteModal {
  overflow: hidden;
}
.bill-modl-input-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.INV-modal .modal-dialog {
  max-width: 47%;
}
.INV-modal .billing-heading h3 {
  font-family: Poppins-SemiBold;
  font-size: 26px;
}

.grid-inpt-modal input,
.grid-inpt-modal textarea {
  color: var(--greyish-black);
  font-size: 20px;
  border: 1px solid var(--black-common);
  font-family: Poppins-Regular;
}
.grid-inpt-modal textarea {
  min-height: 121px;
  resize: none;
}
.grid-inpt-modal input {
  min-height: 59px;
}
.grid-inpt-modal input,
.grid-inpt-modal label,
.grid-inpt-modal textarea {
  width: 100%;
}
.grid-inpt-modal input {
  padding: 0 0.5rem;
}
.grid-inpt-modal-parent {
  display: grid;
  gap: 5%;
  grid-template-columns: 20% 35% 35%;
}
.table-modal-inner p {
  font-size: 15px;
  font-family: Poppins-SemiBold;
  color: var(--white-common);
  margin: 0;
}
.table-modal-inner-parent {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.table-modal-inner {
  min-height: 53px;
  display: grid;
  align-items: center;
}
.table-modal-parent {
  border: 1px solid #b9b9b9;
}
.mdl-top-border {
  border-top: 1px solid #b9b9b9;
}
.mdl-right-border {
  border-right: 1px solid #b9b9b9;
}
.pd-left-mdl-ttl {
  padding-left: 5%;
}
.inv-modal-input-parent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
}
.grid-inpt-modal textarea {
  padding: 0.5rem 0.5rem;
}

/* modal styles */

/* Sales-Page Styles */
.inner-sale-grid {
  display: grid;
  gap: 2%;
  grid-template-columns: 20% 15% 15% 15% 15% 10%;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
}

.date-inpt::-webkit-inner-spin-button,
.date-inpt::-webkit-calendar-picker-indicator {
  font-size: 35px;
  position: relative;
  left: 2%;
  opacity: 0;
}

.sal-view-ordheading h4 {
  font-family: Poppins-Regular;
  font-size: 20px;
  color: var(--greyish-black);
  margin: 0;
}

.sal-view-ordheading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sal-view-ordheading svg {
  font-size: 22px;
  color: var(--greyish-black);
}

.env-box {
  background: var(--greyish-black);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0.8rem 1rem;
}

.env-box h5,
.env-box p {
  font-size: 15px;
  color: var(--white-common);
  margin: 0;
}

.env-box h5 {
  font-family: Poppins-SemiBold;
}

.env-box p {
  font-family: Poppins-Regular;
}

.setting-icn-box svg {
  font-size: 17px;
  color: var(--yellow-prime);
}

.setting-icn-box {
  display: flex;
  align-items: center;
  height: 100%;
  border: 1px solid #e5b211;
  border-radius: 4px;
  padding: 0 0.3rem;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.sales-view-table {
  display: grid;
  gap: 2%;
  grid-template-columns: 34% 28% 34%;
  align-items: center;
  min-height: 51px;
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
}

.sales-view-table-inner p {
  font-family: Poppins-SemiBold;
  font-size: 15px;
  color: var(--black-common);
  margin: 0;
}

.sale-indent p {
  text-indent: 15%;
}

.sales-view-table-semi p {
  font-family: Poppins-Regular;
  width: 100%;
}

.sales-view-table-semi {
  background: transparent !important;
  border: 1px solid var(--greyish-black);
  border-top: none;
}

.brdr-right-sl-view {
  border-right: 1px solid var(--greyish-black);
}

.sales-view-table-semi .sales-view-table-inner {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}
.billing-box {
  background: #e9ecef;
  padding: 1.3rem 1rem;
}
.billing-box h5,
.billing-box p {
  font-size: 15px;
  margin: 0;
}
.billing-box h5 {
  font-family: Poppins-Regular;
  color: var(--black-common);
}
.billing-box p {
  font-family: Poppins-Light;
  color: #707070;
}
.billing-box .setting-icn-box {
  border-radius: 5px;
  padding: 0 0.5rem;
}
.billing-box .setting-icn-box svg {
  font-size: 28px;
}

.py-mthd-box {
  background-color: #e6e6e6;
  border: 1px solid var(--greyish-black);
  border-radius: 3px;
  padding: 0.4rem 1rem;
}

.py-mthd-box h5,
.py-mthd-box p {
  font-family: Poppins-Regular;
}
.py-mthd-box h5 {
  color: rgb(123, 122, 122, 50%);
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.py-mthd-box p {
  color: rgb(123, 122, 122);
  font-size: 14px;
  margin: 0;
}
.sales-view-content-parent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
/* IMG UPLOAD */
span.filepond--label-action i {
  font-size: 40px;
}

.filepond--wrapper {
  width: 30%;
}
textarea {
  resize: none;
}
.ck.ck-content {
  min-height: 200px;
}
/* Sales-Page Styles */

/* responsive requirements */

/* hamburger-styles */
.sidebar-left {
  cursor: pointer;
  position: fixed;
  z-index: 9;
  top: 23rem;
  left: -1rem;
}
.grid-cust-resp {
  grid-template-columns: 5.5% 10.5% 28% 28% 28% !important;
}
.hamburger-icon-sidebar {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  min-height: 140px;
  cursor: pointer;
  transform: scale(0.7);
  background: linear-gradient(79deg, #d89f00, #ffe268, #d89f00);
  padding: 42px 14px;
  border-radius: 0 70px 70px 0px;
}
.hamburger-checkbox-sidebar {
  opacity: 0;
  width: 100%;
  height: 48px;
  position: absolute;
}
.sidebar-left span:nth-of-type(3) {
  width: 100%;
}
.sidebar-left span:nth-of-type(2) {
  width: 50%;
}
.sidebar-left span:nth-of-type(1) {
  width: 100%;
}
.sidebar-left.sidebar-active-humburger {
  transform: translateX(250px);
}

.hamburger-checkbox-sidebar:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(137deg) translate(7px, 0px);
}
.hamburger-checkbox-sidebar:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(44deg) translate(-7px, -14px);
}
.hamburger-checkbox-sidebar:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 56%;
  transform: translate(20px, -14px) rotatez(42deg);
}
.hamburger-icon-sidebar span {
  background: #161616;
  height: 7px;
  margin: 5px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
/* hamburger-styles */

.product-tablle-tag thead tr th {
  background: linear-gradient(#dfa800, #ffe162, #dfa800);
  vertical-align: middle;
}
.product-tablle-tag thead tr {
  border-radius: 10px;
}
.product-tablle-tag tbody tr th,
.product-tablle-tag tbody tr td {
  vertical-align: middle;
}
/* .product-tablle-tag {
  border-collapse: separate;
  border-spacing: 0 15px;
} */
