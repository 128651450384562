@media only screen and (max-width: 1536px) {
  .brn-logo img {
    width: 80%;
  }

  .user-img {
    padding-top: 0.5rem;
  }

  .user-img img {
    width: 24%;
  }

  .user-img h5 {
    font-size: 12px;
    margin: 0.3rem 0 0 0;
  }

  .main-sidebar-list {
    padding: 10px 0px 0px;
    margin: 0 0 40px;
  }

  .flex-list-li a {
    font-size: 16px;
  }

  li.flex-list-li img {
    max-width: 36px;
    padding: 0px 10px 0px 10px;
  }

  ul.side-list-flex {
    margin-left: 40px;
  }

  .side-bar-main {
    width: 290px;
  }

  .second-side-parent {
    width: calc(100% - 290px);
  }

  .logout-btn img {
    max-width: 16px;
  }

  .logout-btn {
    gap: 8px;
    font-size: 16px;
  }

  .flex-list-li {
    margin: 1.2rem 0;
  }

  .dashboard-heading h2 {
    font-size: 65px;
  }

  .dashboard-right-header {
    padding: 0.7rem 0;
  }

  .main-page-box-inner img {
    max-width: 90px;
  }

  .main-page-box-inner h3 {
    font-size: 26px;
    margin: 0.7rem 0 0 0;
  }

  .main-page-box-inner h4 {
    font-size: 57px;
    margin: 0 0 0.7rem 0;
  }

  .main-page-box-inner a {
    font-size: 17px;
    min-width: 171px;
    padding: 0.3rem 0;
  }

  .main-page-box {
    padding: 4rem 0;
  }

  .order-table-inner p {
    font-size: 11px;
  }

  .order-list-heading h4 {
    font-size: 17px;
  }

  .order-list-heading img {
    padding-right: 0.5%;
    max-width: 18px;
  }

  .order-list-box {
    padding: 1rem 0;
  }

  .order-table-parent {
    min-height: 45px;
  }

  .order-table-sup-parent {
    padding: 0.7rem 0;
    gap: 18px;
  }

  .right-icn img {
    width: 80%;
  }

  .right-icons-parent {
    gap: 5px;
  }

  .dsh-board-inner-head {
    padding: 1.5rem 0 1rem 0;
  }

  .txt-right-pagi p,
  .pr-table p {
    font-size: 11px;
  }

  .pr-table-inner-parent {
    min-height: 30px;
    border-radius: 8px;
  }

  .pr-table img {
    max-width: 40px;
  }

  .pr-table-inner-parent-rev {
    min-height: 41px;
  }

  .dlt-edit img {
    max-width: 26px;
  }

  .pagi img {
    max-width: 110px;
  }

  .pr-table-parent {
    gap: 10px;
    padding: 1.7rem 0;
  }

  .left-chevi img {
    max-width: 10px;
  }

  .input-pr input,
  .input-pr select {
    min-height: 31px;
    font-size: 11px;
    padding: 0 0.5rem;
  }

  .text-input-pr h4,
  .image-box-heading h3 {
    font-size: 11px;
  }

  .input-pr-parent-sup {
    padding: 0.5rem 0;
  }

  .col9inner-add-pr hr {
    margin: 0.5rem;
  }

  .input-pr-parent-pd {
    padding-bottom: 0.7rem;
  }

  .input-pr textarea {
    min-height: 220px;
  }

  .image-box p {
    font-size: 12px;
  }

  .img-plus {
    max-width: 40px;
  }

  .prdt-upld-btn a {
    min-width: 107px;
    min-height: 25px;
    border-radius: 8px;
    font-size: 10px;
  }

  .image-box {
    min-height: 160px;
  }

  .prdt-upld-btn {
    padding: 0.5rem 0 0 0;
  }

  .right-parent-sup {
    gap: 15px;
  }

  .pd-customer-row {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .inner-grid-sales p {
    font-size: 11px;
    min-height: 44px;
  }

  .grid-sales-parent {
    gap: 14px;
  }

  .filter-box-heading h3 img {
    padding-right: 0.3rem;
    max-width: 19px;
  }

  .filter-box-heading h3 {
    font-size: 16px;
  }

  .filter-box-heading {
    min-height: 36px;
    padding: 0 0.7rem;
  }

  .filter-box-body {
    padding: 0.7rem 0;
  }

  .filter-lb-inp-parent label {
    font-size: 11px;
    margin-bottom: 0.1rem;
  }

  .filter-lb-inp-parent input {
    font-size: 9px;
    min-height: 30px;
    padding: 0 0.3rem;
  }

  .reset-filt-parent {
    padding: 1rem 0 0.3rem 0;
  }

  .reset-filt-parent input,
  .reset-filt-parent button {
    font-size: 11px;
    border-radius: 7px;
    min-width: 63px;
    min-height: 25px;
  }

  .reset-filt-parent button img {
    max-width: 10px;
  }

  .grid-sales-parent {
    padding: 0 0 1.5rem 0;
  }

  .hr-edit-cust {
    margin: 0.7rem 0;
  }

  .edit-cust-grid-inner input {
    font-size: 10px;
    border-radius: 6px;
    padding: 0 0.4rem;
    min-height: 24px;
  }

  .edit-cust-grid-inner p {
    font-size: 10px;
  }

  .grid-manage-inner p {
    font-size: 11px;
  }

  .grid-manage-parent {
    border-radius: 8px;
    min-height: 45px;
  }

  .grid-manage-inner p img {
    padding-right: 2%;
    max-width: 13px;
  }

  .mange-star-parent svg {
    font-size: 16px;
  }

  .grid-manage-parent-sup {
    gap: 12px;
    padding: 1rem 0 0.5rem 0;
  }

  .edit-cust-grid-inner textarea {
    min-height: 85px;
    font-size: 11px;
    padding: 4px 8px;
  }

  .edit-mnge-heading h3,
  .edit-mnge-star svg {
    font-size: 24px;
  }

  .edit-mange-cent {
    padding: 0 0 1.5rem 0;
  }

  .app-dec-btn button {
    font-size: 11px;
    min-width: 130px;
    min-height: 31px;
    border-radius: 8px;
  }

  .app-dec-btn {
    gap: 8px;
    padding-top: 1.5rem;
  }

  .login-logo img {
    max-width: 100px;
  }

  .login-box-inner {
    padding: 1rem 0 1.5rem 0;
  }

  .login-logo {
    padding-bottom: 0.4rem;
  }

  .login-head-para h3 {
    font-size: 28px;
    margin: 0 0 0.3rem 0;
  }

  .login-head-para p {
    font-size: 17px;
    margin: 0 0 0.4rem 0;
  }

  .input-and-rem-parent {
    gap: 10px;
  }

  .input-login input {
    min-height: 38px;
    font-size: 13px;
    padding: 0 0 0 3rem;
  }

  .env-icon img,
  .lock-icon img {
    max-width: 20px;
    transform: translateY(-2px);
  }

  .remem-me span {
    font-size: 14px;
  }

  .login-btn a,
  .login-btn button {
    font-size: 13px;
    border-radius: 17px;
    min-width: 155px;
    padding: 0.6rem 0;
  }

  .login-btn {
    padding: 1rem 0 0 0;
  }
  .save-btn-add-pr a,
  .save-btn-add-pr button {
    min-width: 128px;
    min-height: 34px;
    font-size: 12px;
    border-radius: 8px;
  }
  .save-btn-add-pr {
    padding: 1.5rem 0 0 0;
  }
  .date-inpt::-webkit-inner-spin-button,
  .date-inpt::-webkit-calendar-picker-indicator {
    font-size: 28px;
    left: 0%;
  }
  .sal-view-ordheading h4 {
    font-size: 17px;
  }
  .sal-view-ordheading svg {
    font-size: 18px;
  }
  .sal-view-ordheading {
    gap: 8px;
  }
  .sales-view-content-parent {
    gap: 20px;
  }
  .env-box h5,
  .env-box p,
  .sales-view-table-inner p,
  .billing-box h5,
  .billing-box p {
    font-size: 13px;
  }
  .env-box {
    gap: 3px;
    padding: 0.5rem 0.65rem;
  }
  .sales-view-table {
    min-height: 40px;
  }
  .billing-box {
    padding: 1rem 1rem;
  }
  .setting-icn-box svg {
    font-size: 14px;
  }
  .billing-box .setting-icn-box {
    padding: 0 0.4rem;
  }
  .billing-box .setting-icn-box svg {
    font-size: 22px;
  }
  .py-mthd-box p {
    font-size: 12px;
  }
  .py-mthd-box h5 {
    font-size: 10px;
    margin-bottom: 0.3rem;
  }
  .py-mthd-box {
    padding: 0.3rem 0.8rem;
  }
  .delete-modal-content-parent {
    gap: 22px;
    padding: 1.8rem 0;
  }

  .modal-close-button img {
    max-width: 16px;
  }
  .modal-close-button {
    top: 3%;
    right: 3%;
  }
  .dlt-modal-img img {
    max-width: 45px;
  }
  .dlte-modal-txt h3 {
    font-size: 25px;
  }
  .delete-modal-btn-parent button {
    font-size: 10px;
    min-width: 98px;
    min-height: 32px;
    border-radius: 7px;
  }
  .delete-modal-parent::before,
  .delete-modal-parent {
    border-radius: 18px;
  }
  .Tick-modal-img img {
    max-width: 77px;
  }
  .billing-modal-inner-parent {
    padding: 1.2rem 0;
  }
  .billing-heading h3 {
    font-size: 19px;
  }
  .inner-input-bill-mdl label {
    font-size: 12px;
    margin-bottom: 0.3rem;
  }
  .inner-input-bill-mdl input {
    font-size: 9px;
    min-height: 28px;
    padding: 0 0.4rem;
  }
  .bill-modl-input-parent {
    gap: 5px;
  }
  .save-btn-bill {
    padding-top: 1.2rem;
  }
  .billing-modal-inner-parent .modal-close-button {
    top: 2.8%;
  }
  .INV-modal .billing-heading h3 {
    font-size: 22px;
  }
  .inv-modal-input-parent {
    gap: 11px;
    padding: 1rem 0;
  }
  .grid-inpt-modal label {
    font-size: 12px;
    margin-bottom: 0.2rem;
  }
  .grid-inpt-modal input {
    min-height: 42px;
  }
  .grid-inpt-modal input,
  .grid-inpt-modal textarea {
    font-size: 16px;
  }
  .grid-inpt-modal textarea {
    min-height: 91px;
  }
  .table-modal-inner p {
    font-size: 12px;
  }
  .table-modal-inner {
    min-height: 42px;
  }
}

@media only screen and (max-width: 1366px) {
  .brn-logo img {
    width: 70%;
  }

  .user-img img {
    width: 21%;
  }

  .user-img h5 {
    font-size: 11px;
    margin: 0.2rem 0 0 0;
  }

  .flex-list-li a {
    font-size: 14px;
  }

  li.flex-list-li img {
    max-width: 29px;
    padding: 0px 7px 0px 7px;
  }

  ul.side-list-flex {
    margin-left: 35px;
  }

  .logout-btn {
    gap: 7px;
    font-size: 14px;
  }

  .logout-btn img {
    max-width: 15px;
  }

  .main-sidebar-list {
    margin: 0 0 30px;
  }

  .flex-list-li {
    margin: 1rem 0;
  }

  .side-bar-main {
    width: 270px;
  }

  .second-side-parent {
    width: calc(100% - 270px);
  }

  .dashboard-heading h2 {
    font-size: 50px;
  }

  .dashboard-right-header {
    padding: 0.6rem 0;
  }

  .main-page-boxes-parent {
    padding: 2rem 0;
    gap: 23px;
  }

  .main-page-box-inner img {
    max-width: 75px;
  }

  .main-page-box-inner h3 {
    font-size: 21px;
    margin: 0.6rem 0 0 0;
  }

  .main-page-box-inner h4 {
    font-size: 50px;
    margin: 0 0 0.6rem 0;
  }

  .main-page-box-inner a {
    font-size: 15px;
    min-width: 151px;
    padding: 0.25rem 0;
  }

  .main-page-box {
    padding: 3.5rem 0;
  }

  .order-list-heading h4 {
    font-size: 15px;
  }

  .order-list-heading img {
    max-width: 17px;
  }

  .order-table-parent {
    min-height: 38px;
    border-radius: 7px;
  }

  .order-table-inner p {
    font-size: 9px;
  }

  .order-table-sup-parent {
    gap: 15px;
  }

  .dsh-board-inner-head {
    padding: 1.2rem 0 0.9rem 0;
  }

  .right-icn img {
    width: 70%;
  }

  .dashboard-right-body {
    border-radius: 30px;
  }

  .txt-right-pagi p,
  .pr-table p {
    font-size: 9px;
  }

  .pr-table img {
    max-width: 35px;
  }

  .pr-table-inner-parent {
    min-height: 25px;
    border-radius: 6px;
  }

  .pr-table-inner-parent-rev {
    min-height: 35px;
  }

  .dlt-edit img {
    max-width: 20px;
  }

  .dlt-edit {
    padding-bottom: 0.3rem;
  }

  .pagi img {
    max-width: 90px;
  }

  .inner-pages-body {
    padding: 1.5rem 0;
  }

  .left-chevi img {
    max-width: 7px;
  }

  .pr-add-heading {
    padding: 1rem 0;
  }

  .text-input-pr h4,
  .image-box-heading h3 {
    font-size: 9px;
  }

  .input-pr-parent-sup {
    padding: 0.3rem 0;
  }

  .input-pr input,
  .input-pr select {
    min-height: 25px;
    font-size: 9px;
    padding: 0 0.4rem;
  }

  .input-pr textarea {
    min-height: 160px;
    font-size: 10px;
    padding: 0 0.5rem;
  }

  .img-plus {
    max-width: 30px;
  }

  .image-box p {
    font-size: 9px;
  }

  .prdt-upld-btn a {
    min-width: 87px;
    min-height: 21px;
    border-radius: 6px;
    font-size: 8px;
  }

  .image-box {
    min-height: 140px;
  }

  .inner-grid-sales p {
    font-size: 9px;
    min-height: 34px;
  }

  .pd-customer-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .grid-sales-parent {
    padding: 0 0 1rem 0;
  }

  .filter-box-heading {
    min-height: 30px;
    padding: 0 0.6rem;
  }

  .filter-box-heading h3 img {
    padding-right: 0.2rem;
    max-width: 13px;
  }

  .filter-box-heading h3 {
    font-size: 14px;
  }

  .filter-box-body {
    padding: 0.3rem 0;
  }

  .filter-lb-inp-parent label {
    font-size: 9px;
    margin-bottom: 0rem;
  }

  .filter-lb-inp-parent input {
    font-size: 8px;
    min-height: 25px;
    padding: 0 0.25rem;
  }

  .reset-filt-parent {
    padding: 0.7rem 0 0.2rem 0;
  }

  .reset-filt-parent input,
  .reset-filt-parent button {
    font-size: 9px;
    border-radius: 5px;
    min-width: 54px;
    min-height: 21px;
  }

  .filter-lb-inp-parent {
    padding-bottom: 0rem;
  }

  .cust-det-head {
    padding-top: 1rem;
  }

  .hr-edit-cust {
    margin: 0.5rem 0;
  }

  .edit-cust-grid-inner p {
    font-size: 9px;
  }

  .edit-cust-grid-inner input {
    font-size: 8px;
    border-radius: 5px;
    min-height: 21px;
  }

  .customer-right-body .inner-pages-body {
    padding: 2rem 0 1.5rem 0;
  }

  .grid-manage-inner p {
    font-size: 9px;
  }

  .grid-manage-parent {
    border-radius: 6px;
    min-height: 38px;
  }

  .grid-manage-inner a img {
    max-width: 10px;
    padding: 0 0 5px 0;
  }

  .grid-manage-parent-sup {
    gap: 10px;
    padding: 0.8rem 0 0.3rem 0;
  }

  .mnge-rev-pagi {
    padding: 0.7rem 0;
  }

  .mange-star-parent svg {
    font-size: 13px;
  }

  .grid-manage-inner p img {
    max-width: 10px;
  }

  .edit-mnge-heading h3,
  .edit-mnge-star svg {
    font-size: 20px;
  }

  .edit-mange-cent {
    padding: 0 0 1rem 0;
    gap: 6px;
  }

  .edit-cust-grid-inner textarea {
    min-height: 65px;
    font-size: 9px;
    padding: 4px 7px;
  }

  .app-dec-btn {
    gap: 6px;
    padding-top: 1rem;
  }

  .app-dec-btn button {
    font-size: 9px;
    min-width: 110px;
    min-height: 28px;
    border-radius: 7px;
  }

  .login-logo img {
    max-width: 75px;
  }

  .login-head-para h3 {
    font-size: 23px;
  }

  .login-head-para p {
    font-size: 14px;
  }

  .input-login input {
    min-height: 32px;
    font-size: 11px;
    padding: 0 0 0 2.5rem;
  }

  .env-icon img,
  .lock-icon img {
    max-width: 16px;
  }

  .remem-me span {
    font-size: 11px;
  }

  .login-btn {
    padding: 0.7rem 0 0 0;
  }
  .login-btn a,
  .login-btn button {
    font-size: 11px;
    border-radius: 13px;
    min-width: 125px;
    padding: 0.45rem 0;
  }
  .login-box-inner {
    padding: 0.7rem 0 1.1rem 0;
  }
  .eye-icon {
    font-size: 14px;
  }
  .inner-sale-grid {
    border-radius: 7px;
  }
  .save-btn-add-pr {
    padding: 1rem 0 0 0;
  }
  .save-btn-add-pr a,
  .save-btn-add-pr button {
    min-width: 108px;
    min-height: 29px;
    font-size: 10px;
    border-radius: 7px;
  }
  .inner-grid-sales p a img {
    filter: invert(1);
    max-width: 10px;
  }
  .date-inpt::-webkit-inner-spin-button,
  .date-inpt::-webkit-calendar-picker-indicator {
    font-size: 22px;
    left: -2%;
  }
  .sal-view-ordheading svg,
  .sal-view-ordheading h4 {
    font-size: 15px;
  }
  .sal-view-ordheading {
    gap: 5px;
  }
  .sales-view-content-parent {
    gap: 15px;
  }
  .env-box h5,
  .env-box p,
  .sales-view-table-inner p,
  .billing-box h5,
  .billing-box p {
    font-size: 10px;
  }
  .setting-icn-box {
    padding: 0 0.25rem;
  }
  .env-box {
    padding: 0.45rem 0.65rem;
  }
  .sales-view-table {
    min-height: 33px;
  }
  .billing-box {
    padding: 0.7rem 0.8rem;
  }
  .py-mthd-box h5 {
    font-size: 8px;
    margin-bottom: 0.2rem;
  }
  .py-mthd-box p {
    font-size: 10px;
  }
  .delete-modal-content-parent {
    gap: 19px;
    padding: 1.6rem 0;
  }
  .dlt-modal-img img {
    max-width: 38px;
  }
  .dlte-modal-txt h3 {
    font-size: 21px;
  }
  .delete-modal-btn-parent button {
    font-size: 9px;
    min-width: 88px;
    min-height: 27px;
    border-radius: 6px;
  }
  .modal-close-button img {
    max-width: 13px;
  }
  .tick-modal-parent {
    padding: 1.2rem 0;
  }
  .Tick-modal-img img {
    max-width: 70px;
  }
  .billing-modal-inner-parent {
    padding: 1rem 0;
  }
  .billing-heading h3 {
    font-size: 16px;
  }
  .inner-input-bill-mdl label {
    font-size: 10px;
    margin-bottom: 0rem;
  }
  .inner-input-bill-mdl input {
    font-size: 8px;
    min-height: 22px;
    padding: 0 0.35rem;
  }
  .bill-modl-input-parent {
    gap: 0px;
  }
  .billing-modal-parent hr {
    margin: 0.5rem 0;
  }
  .save-btn-bill {
    padding-top: 1rem;
  }
  .billing-modal-inner-parent .modal-close-button {
    top: 2%;
  }
  .INV-modal .billing-heading h3 {
    font-size: 18px;
  }
  .grid-inpt-modal label {
    font-size: 10px;
    margin-bottom: 0.1rem;
  }
  .grid-inpt-modal input,
  .grid-inpt-modal textarea {
    font-size: 11px;
  }
  .grid-inpt-modal input {
    min-height: 35px;
    padding: 0 0.3rem;
  }
  .grid-inpt-modal textarea {
    min-height: 71px;
    padding: 0.3rem;
  }
  .inv-modal-input-parent {
    gap: 5px;
    padding: 0.7rem 0;
  }
  .table-modal-inner p {
    font-size: 10px;
  }
  .table-modal-inner {
    min-height: 34px;
  }
}

@media only screen and (max-width: 1280px) {
  .flex-list-li {
    margin: 0.7rem 0;
  }

  li.flex-list-li a {
    padding: 5px 5px;
  }

  .flex-list-li a {
    font-size: 12px;
  }

  .side-bar-main {
    width: 250px;
  }

  .second-side-parent {
    width: calc(100% - 250px);
  }

  .logout-btn {
    gap: 5px;
    font-size: 13px;
  }

  .logout-btn img {
    max-width: 13px;
  }

  .flex-list-li a:hover,
  .activebarside a {
    border-radius: 8px 0px 0px 8px;
  }

  .dashboard-heading h2 {
    font-size: 47px;
  }
}
@media only screen and (max-width: 999px) {
  .main-page-box {
    margin: 0 0 22px;
  }
  .sales-anlytics {
    margin: 0 0 10px;
  }
  .dashboard-right-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
  .right-icn img {
    width: 100%;
  }
  .dashboard-content-parent {
    width: 90%;
    margin: auto;
  }

  .second-side-parent {
    width: calc(100% - 0px);
  }
  .main-table-parent-rsp {
    width: 1000px;
  }
  .main-table-parent-rsp-master {
    overflow-x: scroll;
  }

  .main-table-parent-rsp-master::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(148, 148, 148);
  }

  .main-table-parent-rsp-master::-moz-scrollbar {
    width: 8px;
    background-color: rgb(148, 148, 148);
  }
  .inner-pages-body {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
  }
  .main-div10 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .input-pr-parent {
    gap: 2%;
    flex-direction: row;
  }
  .prdt-upld-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .save-btn-add-pr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .input-pr textarea {
    min-height: 160px;
    font-size: 10px;
    padding: 0 0.5rem;
    width: 100%;
  }
  .sidebar-left {
    transition: all 0.4s ease-in-out;
    transform: translateX(0px);
  }
  .sidebar-left.sidebar-active-humburger {
    transform: translateX(180px) rotate(180deg);
  }
  .first-side-bar {
    transition: all 0.4s ease-in-out;
    position: fixed;
    left: -100%;
    z-index: 1;
  }
  .first-side-bar.sidebar-active-humburger {
    position: fixed;
    left: 0%;
  }
  .filter-unactive {
    display: none !important;
    transition: all 0.4s ease-in-out;
    position: relative;
    z-index: 1;
  }
  .filter-unactive.filter-active {
    transform: translateX(0%);
    display: block;
  }
  .user-img img {
    width: 31%;
  }
  .user-img {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  .flex-list-li a {
    font-size: 16px;
  }
  .flex-list-li {
    margin: 3rem 0;
  }
  .input-pr {
    font-size: 20px;
  }
  .input-pr input,
  .input-pr select {
    font-size: 14px;
  }
  .text-input-pr h4,
  .image-box-heading h3 {
    font-size: 12px;
  }
  .img-plus {
    max-width: 50px;
  }
  .DeleteModal .modal-dialog {
    max-width: 70%;
  }
  .order-list-heading h4 {
    font-size: 20px;
  }
  .order-list-heading img {
    max-width: 20px;
  }
  .main-page-box-inner a {
    font-size: 16px;
  }
  .pr-table p {
    font-size: 14px;
  }
  .txt-right-pagi p,
  .pr-table p {
    font-size: 13px;
  }
  .inner-grid-sales p {
    font-size: 12px;
  }
  .grid-manage-inner p {
    font-size: 12px;
  }
  .text-input-pr h4,
  .image-box-heading h3 {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-box-heading h3 {
    margin-top: 15px;
  }
  .app-dec-btn button {
    font-size: 15px;
    min-width: 160px;
    min-height: 40px;
    border-radius: 7px;
  }
  .text-input-pr h4,
  .image-box-heading h3 {
    font-size: 13px;
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .filter-box-heading {
    display: none;
  }
  .filter-opn-btn {
    padding: 18px 16px !important;
  }
  .filter-opn-btn button {
    font-size: 12px !important;
    min-width: 85px !important;
    min-height: 30px !important;
  }
  .input-pr-parent {
    display: block;
  }
  .justify-content-end {
    justify-content: flex-start !important;
  }
  .text-input-pr h4,
  .image-box-heading h3 {
    margin-bottom: 10px;
  }
  .web-cust-filter {
    display: none;
  }
  .filter-box-parent {
    width: 50%;
    position: absolute;
    right: 0rem;
    top: 4rem;
  }
  .env-box {
    padding: 1rem 1.65rem;
    margin: 10px 0;
  }
  .billing-box {
    padding: 0.7rem 0.8rem;
    margin: 20px 0;
  }
  .side-bar-main {
    position: fixed;
  }
  .flex-list-li a:hover,
  .activebarside a {
    border-radius: 50px 0px 0px 50px;
  }
  .grid-manage-inner a img {
    max-width: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .filter-box-parent {
    top: 3rem;
  }
  .hamburger-checkbox-sidebar:checked ~ span:nth-of-type(3) {
    transform: translate(15px, -15px) rotatez(44deg);
  }
  .hamburger-checkbox-sidebar:checked ~ span:nth-of-type(2) {
    transform: rotatez(45deg) translate(-7px, -13px);
  }
  .hamburger-checkbox-sidebar:checked ~ span:nth-of-type(1) {
    transform: rotatez(132deg) translate(7px, 0px);
  }
  .sidebar-left.sidebar-active-humburger {
    transform: translateX(198px) rotate(180deg);
  }
  .hamburger-icon-sidebar {
    min-width: 80px;
    min-height: 100px;
  }
  .flex-list-li {
    margin: 2rem 0;
  }
  .sidebar-left {
    top: 18rem;
  }
  .filter-box-parent {
    width: 70%;
  }
  .filter-opn-btn {
    padding: 4px 10px !important;
  }
  .dashboard-heading h2 {
    font-size: 32px;
  }
  .order-list-heading h4 {
    font-size: 18px;
  }
  .order-list-heading img {
    max-width: 15px;
  }
  .inner-grid-sales p {
    font-size: 12px;
  }
  .main-div10.d-flex.justify-content-between p {
    font-size: 13px;
  }
  .main-page-box-inner a {
    font-size: 14px;
  }
  .pr-table p {
    font-size: 11px;
  }
  .DeleteModal .modal-dialog {
    max-width: 80%;
  }
  .dlte-modal-txt h3 {
    font-size: 20px;
    text-align: center;
  }
  .right-icn img {
    width: 65%;
  }
  .prdt-upld-btn {
    min-width: 108px;
    min-height: 29px;
    border-radius: 7px;
    font-size: 10px;
  }
  .dashboard-heading h2 {
    font-size: 27px;
  }
  .edit-mnge-heading h3,
  .edit-mnge-star svg {
    font-size: 15px;
  }
  .order-list-heading h4 {
    font-size: 20px;
  }
  .edit-cust-grid-inner p {
    font-size: 12px;
  }
  .edit-cust-grid-inner input {
    font-size: 10px;
  }
  .app-dec-btn {
    flex-direction: column;
  }
  .app-dec-btn button {
    font-size: 13px;
    min-width: 110px;
    min-height: 30px;
    border-radius: 7px;
  }
  .modal-dialog {
    margin: auto;
  }
  .input-pr-parent {
    display: block;
  }
  .justify-content-end {
    justify-content: flex-start !important;
  }
  .text-input-pr h4,
  .image-box-heading h3 {
    margin-bottom: 10px;
  }
  .side-bar-main {
    position: fixed;
  }
}
@media only screen and (max-width: 385px) {
  .main-page-box-inner a {
    font-size: 12px;
  }
  .side-bar-main {
    position: fixed;
  }
}
