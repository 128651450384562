:root {
  --yellow-prime: #ffc917;
  --black-common: #161616;
  --white-common: #f8ffff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../font/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../font/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../font/Poppins-Bold.ttf);
}

@font-face {
  font-family: Bebas-Bold;
  src: url(../font/BebasNeue-Bold.ttf);
}
@font-face {
  font-family: Chandler-Light;
  src: url(../font/chandler.otf);
}
.side-active a {
  color: var(--yellow-prime) !important;
  font-family: Poppins-SemiBold !important;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: var(--yellow-prime);
}

::-moz-scrollbar {
  width: 12px;
  background-color: #dbdada;
}

::-moz-scrollbar-thumb {
  background-color: var(--yellow-prime);
}

img,
svg {
  vertical-align: middle;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Banner and Sidebar styles */
.Banner-main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.container-custom {
  width: 1550px;
  margin: 0 auto;
}

.Banner-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content-parent-banner {
  position: relative;
  z-index: 3;
}

.parent-top-logo {
  position: relative;
}

.parent-top-logo {
  padding: 2rem 0 7rem 0;
}

.logo-banner img {
  width: 10%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: #f1f1f1;
  padding: 5rem 1.5rem;
  height: 100vh;
  transform: rotate(180deg);
}

.inner-sidebar-parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.inner-sidebar a {
  display: inline-block;
  font-family: Poppins-Regular;
  font-size: 22px;
  color: var(--black-common);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.inner-sidebar a:hover {
  color: var(--yellow-prime);
}

.inner-sidebar {
  writing-mode: tb;
  margin: 0 auto;
}

.right-hang-circ span {
  background: var(--yellow-prime);
  display: inline-block;
  padding: 0.65rem;
  border-radius: 50%;
}

.right-hang-circ-parent {
  display: flex;
  gap: 15px;
}

.free-call-hang-right h4,
.free-call-hang-right a {
  font-family: Poppins-SemiBold;
  font-size: 22px;
  color: var(--white-common);
  margin: 0;
}
.free-call-hang-right a {
  text-decoration: none;
  display: block;
}

.fw-400-reg-poppins {
  font-family: Poppins-regular !important;
}

.right-hang-banner-content {
  display: flex;
  justify-content: end;
  gap: 1%;
}

.right-hang-banner-parent {
  position: absolute;
  top: 30%;
  width: 100%;
}

.bannerbtm-img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bannerbtm-img img {
  width: 100%;
}

.banner-heading h2 {
  font-size: 89px;
  color: var(--white-common);
  font-family: Poppins-SemiBold;
}

.banner-para p {
  font-size: 22px;
  color: var(--white-common);
  font-family: Poppins-Regular;
  margin: 0;
}

.banner-txt-parent {
  width: 50%;
  margin: 0 auto;
}
.logo-banner-rev a {
  position: relative;
  z-index: 999;
}

/* Banner and Sidebar styles */

/* Section one Index styles */
.sec-one-right-parent img {
  width: 98%;
}
.exp-right-ind-one {
  position: absolute;
  top: 0%;
  left: -2%;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  height: 100%;
  text-align: center;
  padding: 6rem 0;
}
.exp-right-ind-one h3 {
  background-color: var(--yellow-prime);
  color: var(--white-common);
  font-size: 26px;
  margin: 0;
  font-family: Poppins-Medium;
  padding: 0 0.9rem;
}

.ind-sec-one-heading h2 {
  font-size: 64px;
  color: var(--black-common);
  font-family: Poppins-SemiBold;
  margin: 0;
}

.ind-sec-one-para p {
  font-size: 16px;
  color: var(--black-common);
  margin: 0;
  font-family: Poppins-Regular;
}
.ind-sec-one-sub-head h3 {
  font-size: 37px;
  color: var(--yellow-prime);
  margin: 0;
  font-family: Poppins-SemiBold;
}
.ind-sec-one-sub-head h4 {
  font-size: 27px;
  color: var(--yellow-prime);
  margin: 0;
  font-family: Poppins-SemiBold;
}
.ind-sec-one-para {
  margin: 0.7rem 0 1rem 0;
}
.ind-sec-one-sub-head hr {
  color: var(--black-common);
  opacity: 0.3;
}
.content-parent-sec-one-ind {
  width: 90%;
}
.sec_one-index {
  padding: 2rem 0;
}

/* Section one Index styles */

/* Section two Index styles */
.sec-two-ind {
  background: var(--yellow-prime);
  padding: 2rem 0 4rem 0;
}
.sec-two-ind-heading h2,
.sec-three-heading h2,
.heading-sec-four h2,
.our-speci-sub-head h2,
.heading-expertise-row h2 {
  font-family: Poppins-SemiBold;
  font-size: 64px;
  margin: 0;
}
.sec-two-ind-heading h2 {
  color: var(--white-common);
}

.slider-item-parent img {
  width: 95%;
  max-height: 490px;
}
.sec-two-ind-heading {
  padding: 1rem 0 2rem 0;
}
.slider-item-parent {
  position: relative;
  outline: none;
  text-align: center;
}
.slider-item-parent-one:before {
  content: "01";
  position: absolute;
  bottom: 3%;
  left: 3%;
  width: 100%;
  font-family: Poppins-SemiBold;
  font-size: 62px;
  color: var(--white-common);
  text-align: start;
}
.slider-item-parent-two:before {
  content: "02";
  position: absolute;
  bottom: 3%;
  left: 3%;
  width: 100%;
  font-family: Poppins-SemiBold;
  font-size: 62px;
  color: var(--white-common);
  text-align: start;
}
.slider-item-parent-three:before {
  content: "03";
  position: absolute;
  bottom: 3%;
  left: 3%;
  width: 100%;
  font-family: Poppins-SemiBold;
  font-size: 62px;
  color: var(--white-common);
  text-align: start;
}

.slider-item-parent-one::after {
  content: "RISK MANAGEMENT";
  position: absolute;
  top: 0;
  left: 0;
  font-family: Poppins-SemiBold;
  width: 95%;
  height: 0%;
  opacity: 0;
  background: #00000071;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 30px;
}
.slider-item-parent-two::after {
  content: "EMERGENCY RESPONSE PREPAREDNESS & MANAGEMENT";
  position: absolute;
  top: 0;
  left: 0;
  font-family: Poppins-SemiBold;
  width: 95%;
  padding: 0 1rem;
  height: 0%;
  opacity: 0;
  background: #00000071;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 30px;
}
.slider-item-parent-three::after {
  content: "CORRECTIVE ACTION REGISTERS & IMPROVEMENT PLANS";
  position: absolute;
  top: 0;
  left: 0;
  font-family: Poppins-SemiBold;
  width: 95%;
  padding: 0 2rem;
  height: 0%;
  opacity: 0;
  background: #00000071;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 30px;
}

.slider-item-parent:hover::after {
  height: 100%;
  opacity: 1;
}
.sec-two-ind .slick-dots,
.sec-four-ind .slick-dots,
.sec-three-ind .slick-dots {
  bottom: -8%;
  text-align: left;
  width: 20%;
}
/* .sec-two-ind .slick-dots li button {
    width: 50px;
    height: 10px;
} */
.sec-two-ind .slick-dots li button:before,
.sec-four-ind .slick-dots li button:before,
.sec-three-ind .slick-dots li button:before {
  content: "";
}
.sec-two-ind .slick-active button:before {
  content: "";
  background: #000;
  text-align: center;
  width: 100%;
  height: 5px;
  z-index: 2;
}

.sec-three-ind .slick-dots li button:before {
  content: "";
  background: transparent;
  text-align: center;
  width: 100%;
  height: 7px;
  top: 14px;
  z-index: 2;
}

.sec-three-ind .slick-dots .slick-active button:before {
  background: #000;
}

.sec-three-ind .slick-dots {
  border-bottom: 2px solid #e9e90c;
}

.sec-two-ind .slick-dots li,
.sec-four-ind .slick-dots li,
.sec-three-ind .slick-dots li {
  width: 33%;
  margin: 0;
}
.sec-two-ind .slick-dots:before,
.sec-four-ind .slick-dots:before,
.sec-three-ind .slick-dots:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 52%;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #fff;
}
.sec-two-ind .slick-dots li button,
.sec-four-ind .slick-dots li button,
.sec-three-ind .slick-dots li button {
  padding: 0;
}

.sec-three-ind .slick-dots {
  left: 50%;
  transform: translateX(-50%);
}
/* Section two Index styles */

/* Section three Index styles */
.sec-three-heading h2 {
  color: var(--black-common);
}
.sec-three-ind {
  padding: 4rem 0;
}
.img-sec-three-ind img {
  background-color: #ffc917;
  background-color: var(--yellow-prime);
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  height: 500px;
}
.sec-three-row-parent {
  padding: 3rem 0 0 0;
}
.img-sec-three-ind {
  width: 85%;
  margin: 0 auto;
}
.img-sec-three-ind,
.img-sec-three-ind-parent {
  position: relative;
}

/* .img-sec-three-ind:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  z-index: 1;
  background: rgb(0, 0, 0, 53%);
  border-radius: 16px;
  opacity: 0;
  transition: 0.3s ease-in-out;
} */
.img-sec-three-ind a {
  position: relative;
  height: 100%;
  display: block;
}
.img-sec-three-ind:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(0, 0, 0, 53%);
  border-radius: 16px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.img-sec-three-ind:hover::before {
  opacity: 1;
}
.descrip-heading h3,
.descrip-amount h5 {
  color: var(--white-common);
  font-size: 28px;
}
.pricing-star h4 {
  color: #666666;
  font-size: 22px;
  font-family: Poppins-Regular;
}
.star-descrip p {
  font-family: Poppins-SemiBold;
}
.descrip-heading h3 {
  font-family: Poppins-SemiBold;
}
.descrip-amount h5 {
  font-family: Poppins-Regular;
  margin: 0 0 2rem 0;
}
.star-descrip svg {
  color: var(--yellow-prime);
  font-size: 18px;
}
.descrip-btn-parent {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.descrip-sec-three-img-parent {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.img-sec-three-ind:hover .descrip-sec-three-img-parent {
  opacity: 1;
}
.descrip-btn-parent a,
.descrip-btn-parent button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 171px;
  min-height: 40px;
  font-size: 20px;
  font-family: Poppins-SemiBold;
  border-radius: 16px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 3;
}

.descrip-btn-parent button {
  border: none;
}
.add-btn,
.quick-btn {
  color: var(--black-common);
  background: var(--yellow-prime);
}
.add-btn:hover,
.quick-btn:hover {
  background: var(--black-common);
  color: var(--yellow-prime);
}

.add-to-cart-tab-btn button {
  border: none;
}
/* .quick-btn {
  color: var(--white-common);
  background: var(--black-common);
}
.quick-btn:hover {
  background: var(--yellow-prime);
  color: var(--black-common);
} */
.star-descrip {
  margin: 0 0 0.5rem 0;
}
/* Section three Index styles */

/* Section four Index styles */
.sec-four-ind {
  padding: 2rem 0;
}
.heading-sec-four h2 {
  color: var(--black-common);
  margin-bottom: 1rem;
}
.heading-sec-four h5 {
  font-family: Poppins-Medium;
  font-size: 27px;
  color: var(--black-common);
  margin: 0;
}
.insta-slider-item-parent img {
  width: 100%;
}
.sec-four-ind .slick-track {
  display: flex;
  gap: 40px;
  padding: 0rem 0 2rem 0;
}
.insta-slider-item-parent {
  outline: none;
  padding: 2rem 0;
}
.slick-current .insta-slider-item-parent img {
  transform: scale(1.1);
  transition: 0.4s ease;
}
.sec-four-ind .slick-active button:before {
  content: "";
  background: var(--yellow-prime);
  text-align: center;
  width: 100%;
  height: 5px;
  z-index: 2;
  opacity: 1 !important;
  top: 10%;
}
.sec-four-ind .slick-dots {
  bottom: -8%;
  text-align: center;
  width: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.sec-four-ind .slick-dots li button:before {
  content: "";
}

.sec-four-ind .slick-dots li {
  width: 16.65%;
  margin: 0;
}
.sec-four-ind .slick-dots:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 52%;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #000000;
}
.sec-four-ind .slick-dots li button {
  padding: 0;
  width: 100%;
}
.slider-ins-parent {
  padding: 2rem 0;
}
/* .slick-current {
    transform: scale(1.05);
} */
/* Section four Index styles */

/* modal-styles-webiste */
.img-modal-web .modal-dialog {
  max-width: 31%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-modal-cancel {
  position: absolute;
  right: -2%;
  top: -3%;
}
.img-modal-cancel img {
  cursor: pointer;
}
.modal-img img {
  width: 90%;
}
.modal-img {
  text-align: center;
}
/* modal-styles-webiste */

/* Footer styles */
footer {
  background: #000000;
  padding: 2rem 0 1rem 0;
}
.logo-fo {
  border-right: 2px solid rgb(255 254 254 / 25%);
  height: 100%;
  width: 100%;
}
.logo-fo img {
  width: 58%;
}
.fo-heading h3 {
  font-size: 26px;
  font-family: Poppins-SemiBold;
  color: var(--white-common);
  margin: 0;
}

.fo-desc svg {
  color: var(--yellow-prime);
  font-size: 18px;
  padding-right: 4%;
}
.fo-desc span,
.fo-desc a {
  color: var(--white-common);
  font-family: Poppins-Regular;
  font-size: 20px;
  text-decoration: none;
}
.fo-desc-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2rem 0 0 0;
}
.fo-mid-sup-parent {
  display: grid;
  gap: 10%;
  grid-template-columns: 50% 40%;
}
.fo-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 2rem 0 0 0;
}

.fo-form {
  width: 85%;
  margin: 0 auto;
}
.fo-field,
.fo-textarea {
  font-family: Poppins-Regular;
  color: var(--black-common);
  font-size: 21px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
}
.fo-textarea {
  min-height: 160px;
}
.submit-btn-fo {
  max-width: 162px;
  min-height: 48px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 19px;
  font-family: Poppins-SemiBold;
  background: var(--yellow-prime);
  color: var(--white-common);
  transition: 0.3s ease-in-out;
}
.submit-btn-fo:hover {
  background: var(--white-common);
  color: var(--black-common);
}
.copy-right-parent {
  margin: 2rem 0 0 0;

  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  max-width: 548px;
  min-height: 62px;
  align-items: center;
  padding: 0 0px 0px 2rem;
}
.copy-right-parent {
  margin: 2rem 0 0 0;
  background-image: url(../imgs/copy-right-bg.png);
  background-repeat: no-repeat;
  display: flex;
  max-width: 47rem;
  min-height: 62px;
  align-items: center;
  padding: 0 0px 0px 2rem;
}
.copy-right-parent p {
  margin: 0;
  font-family: Poppins-Regular;
  color: var(--white-common);
  font-size: 14px;
}
.copy-right-parent2 {
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 0;
}
/* Footer styles */

/* Shop Page Styles */
.middle {
  transform: translateY(80%);
}

.show-result p {
  color: var(--black-common);
  font-size: 22px;
  font-family: Poppins-Regular;
  margin: 0;
}
.search-bar {
  width: 80%;
  margin: 0 auto;
  border-radius: 27px;
}
.search-bar input {
  padding: 0 4%;
  width: 88%;
  border-radius: 27px 0 0 27px;
  border: 1px solid var(--black-common);
  font-family: Poppins-Regular;
  font-size: 22px;
  color: var(--black-common);
  border-right: none;
}

.search-bar button {
  width: 12%;
  background: var(--yellow-prime);
  border-radius: 0 27px 27px 0;
  border: none;
  transform: translate(-1px, 2px);
  padding: 0 0.5rem;
  min-height: 51px;
}
.search-bar button svg {
  color: var(--white-common);
  font-size: 30px;
}
.search-bar input,
.search-bar button {
  outline: none;
}

.sorting-drp-down select {
  font-family: Poppins-Regular;
  font-size: 20px;
  width: 100%;
  outline: none;
  cursor: pointer;
}
.sorting-drp-down select option {
  font-size: 16px;
}

.search-bar input,
.sorting-drp-down select {
  min-height: 50px;
  max-height: 50px;
}

.shop-main-row-parent hr {
  opacity: 1;
  margin: 0;
  height: 1px;
  background: var(--yellow-prime);
  color: var(--yellow-prime);
}
.shop-main-row-parent .row .col-lg-4 {
  padding: 3rem 0;
}
.pagi-div-shop img {
  max-width: 30%;
}
.shop-section {
  padding-bottom: 5rem;
}
/* Shop Page Styles */

.readmore a {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 171px;
  min-height: 40px;
  font-size: 20px;
  font-family: Poppins-SemiBold;
  border-radius: 16px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background: black;
  color: white;
  margin: 0 auto;
}

/* Single-Product-styles */
.product-web-left-img img {
  background-color: var(--yellow-prime);
  padding: 2rem;
  border-radius: 1rem;
  width: 100%;
}

.product-web-heading-main h2 {
  font-family: Poppins-SemiBold;
  font-size: 52px;
  color: var(--black-common);
  margin: 0;
}
.product-web-left-img {
  width: 90%;
}

/* stars? */
.rating {
  display: flex;
  width: 100%;
  justify-content: start;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 50px;
  height: 40px;
  /* margin-top: auto; */
  background-image: url(../imgs/mod-star.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url(../imgs/rated.png);
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url(../imgs/rated.png);
}
.active-btn {
  background-color: #fff !important;
}
/* stars? */

.single-product-top-description h4,
.single-product-top-description p {
  color: var(--black-common);
}
.single-product-top-description h4 {
  font-family: Poppins-SemiBold;
  font-size: 20px;
  margin-bottom: 0.5rem;
}
.single-product-top-description p {
  font-family: Poppins-Regular;
  font-size: 16px;
  margin-bottom: 0;
}

.single-product-price h4 {
  font-size: 40px;
  font-family: Poppins-Medium;
  color: var(--yellow-prime);
  margin-bottom: 0;
}

.quantity-heading-prdt h5 {
  font-family: Poppins-SemiBold;
  font-size: 20px;
  color: var(--black-common);
}

.quantity-parent-prdct {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 21%;
  border-left: 2px solid var(--black-common);
  border-right: 2px solid var(--black-common);
  padding: 0.3rem 1rem;
  margin-bottom: 1rem;
}

.add-minus-btns {
  border: none;
  background: none;
  color: var(--black-common);
  font-size: 24px;
  font-family: Poppins-Bold;
}

.input-add-prdct {
  background: none;
  border: none;
  text-align: center;
  font-size: 29px;
  font-family: Poppins-Bold;
  color: var(--black-common);
  outline: none;
}
.product-web-main-right-parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.product-web-top-sec-btn {
  display: flex;
  gap: 15px;
}
.product-web-top-sec-btn a,
.product-web-top-sec-btn button {
  font-size: 15px;
  font-family: Poppins-Medium;
  color: var(--black-common);
  background: var(--yellow-prime);
  border: none;
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 5;
  transition: 0.3s ease-in-out;
}
.product-web-top-sec-btn a::before,
.product-web-top-sec-btn button::before {
  content: "";
  background: var(--black-common);
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.product-web-top-sec-btn a:hover::before,
.product-web-top-sec-btn button:hover::before {
  width: 100%;
}
.product-web-top-sec-btn a:hover,
.product-web-top-sec-btn button:hover {
  color: var(--yellow-prime);
}
.product-web-main-right-btm {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.product-web-main-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.review-sec-heading h2 {
  font-family: Poppins-SemiBold;
  font-size: 35px;
  color: var(--black-common);
  margin-bottom: 0;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--yellow-prime);
}
.review-sec-heading h2 span::before {
  content: "";
  position: absolute;
  bottom: -30%;
  left: 0;
  height: 20%;
  width: 100%;
  background: var(--black-common);
}
.product-review-web {
  padding: 3rem 0;
}
.btn-product-review-parent button,
.btn-product-review-parent a {
  border-radius: 10px;
  background: var(--yellow-prime);
  color: var(--black-common);
  padding: 0.3rem 1.5rem;
  transition: all 0.3s ease-in-out;
  font-family: Bebas-Bold;
  font-size: 20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  z-index: 5;
}
.btn-product-review-parent button:hover,
.btn-product-review-parent a:hover {
  color: #f8ffff;
}
.btn-product-review-parent a:before,
.btn-product-review-parent button:before {
  background: #161616;
  background: var(--black-common);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.3s ease-in-out;
  width: 0;
  z-index: -1;
  border-radius: 10px;
}
.btn-product-review-parent a:hover:before,
.btn-product-review-parent button:hover:before {
  width: 100%;
}
.btn-product-review-parent a:hover img,
.btn-product-review-parent button:hover img {
  filter: brightness(5) invert(1);
}
.btn-product-review-parent button img,
.btn-product-review-parent button i {
  padding-right: 0%;
}
.recent-most select {
  min-width: 126px;
  max-width: 126px;
  font-family: Poppins-Medium;
  font-size: 17px;
  color: var(--black-common);
  border: none;
  cursor: pointer;
  outline: none;
  display: block;
}
.para-rev {
  margin: 0;
  font-family: Poppins-Medium;
  font-size: 20px;
  color: var(--black-common);
}
.most-recent-parent {
  display: flex;
  align-items: center;
  gap: 20px;
}
.customer-name,
.customer-date {
  margin: 0;
  color: var(--black-common);
}
.customer-date {
  font-family: Poppins-SemiBold;
  font-size: 15px;
}
.review-sub-sort {
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--black-common);
}
.customer-review-in-yell .para-rev {
  color: var(--yellow-prime) !important;
}
.rating-star-customer-rev {
  display: flex;
  gap: 5px;
}
.rating-star-customer-rev svg {
  font-size: 20px;
  color: #ff9b29;
}
.customer-desc-para {
  margin: 0;
  font-size: 17px;
  font-family: Poppins-Regular;
  color: var(--black-common);
}
.review-web-sec-content-parent,
.gived-reviews-parent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.customer-review-star-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.related-products-sec {
  padding: 3rem 0;
}

/* Single-Product-styles */

/* Cart Page Styles */

.grid-cart-inner p {
  font-family: Chandler-Light;
  font-size: 33px;
  color: var(--black-common);
  margin: 0;
}
.container-custom-cart {
  width: 61%;
  margin: 0 auto;
}
.grid-cart-parent {
  display: grid;
  align-items: center;
  gap: 2%;
  grid-template-columns: 48% 22% 22% 2%;
}

.cart-resuse-text {
  color: var(--black-common);
  font-size: 29px;
  font-family: Poppins-SemiBold;
  margin: 0;
}
.cart-product-description {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart-product-description img {
  min-width: 84px;
  max-width: 84px;
  border: 1px solid var(--yellow-prime);
  padding: 0.5rem;
}

.cartsection-main .add-minus-btns,
.cartsection-main .input-add-prdct {
  font-family: Poppins-Medium;
}
.cart-sec-price .cart-resuse-text {
  font-family: Poppins-Medium;
}
.grid-cart-super-parent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.total-cart-one {
  font-size: 33px;
  font-family: Chandler-Light;
  margin: 0;
  color: var(--black-common);
}
.total-cart-two {
  font-size: 29px;
  font-family: Poppins-Medium;
  margin: 0;
  color: var(--black-common);
}
.total-cart-page {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}
.total-cart-page {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding-top: 2rem;
  border-top: 2px solid var(--black-common);
}
.cart-btn-parent a {
  font-size: 16px;
  font-family: Poppins-SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--white-common);
  background: var(--yellow-prime);
}
.cart-btn-parent a {
  font-size: 16px;
  font-family: Poppins-SemiBold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  color: var(--white-common);
  background: var(--yellow-prime);
  min-width: 255px;
  min-height: 52px;
  z-index: 2;
  transition: 0.3s ease-in-out;
}
.cart-btn-parent a:hover::before {
  height: 100%;
}
.cart-btn-parent a::before {
  content: "";
  background: var(--black-common);
  width: 100%;
  height: 0%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}
.cart-btn-parent {
  padding: 10rem 0 0 0;
}
.cartsection-main {
  padding: 2rem 0 3rem 0;
}
.cart-cancel-icon {
  display: flex;
  align-items: center;
}
/* Cart Page Styles */

/* checkout-page-styles */
.checkout-inner label,
.checkout-inner input,
.checkout-inner textarea {
  width: 100%;
}
.checkout-inner label {
  font-size: 20px;
  font-family: Poppins-Medium;
  color: var(--black-common);
}
.checkout-inner-span {
  color: #fb0000;
}
.checkout-inner input,
.checkout-inner textarea {
  font-family: Poppins-Regular;
  font-size: 16px;
  color: var(--black-common);
  border: 1px solid var(--yellow-prime);
  background: #e9e9e9;
  padding: 0 2%;
  outline: none;
}
.checkout-inner input {
  min-height: 44px;
}
.checkout-inner textarea {
  min-height: 90px;
}
.checkout-inner label sup {
  top: 0;
  width: 100%;
  display: block;
  padding: 1rem 0 1rem 0;
}
.checkout-input-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.checkout-inner {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.order-sum-heading h2 {
  margin: 0;
  font-family: Poppins-Bold;
  font-size: 38px;
  color: var(--black-common);
}
.order-summary-parent .grid-cart-parent {
  display: grid;
  align-items: center;
  gap: 2%;
  grid-template-columns: 60% 18% 18%;
}
.order-summary-parent {
  width: 95%;
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.payment-deatil-heading h3 {
  margin: 0;
  font-size: 32px;
  font-family: Poppins-SemiBold;
  color: var(--black-common);
}
.payment-detail-box-parent {
  border-radius: 20px;
  background: var(--yellow-prime);
  padding: 2rem;
}

.payment-input label,
.payment-input input {
  width: 100%;
}
.payment-input label {
  font-size: 20px;
  font-family: Poppins-Medium;
  color: var(--black-common);
}
.payment-input input {
  font-size: 14px;
  font-family: Poppins-Regular;
  color: var(--black-common);
  border: 1px solid #707070;
  min-height: 46px;
  padding: 0 2%;
  outline: none;
}
.payment-input {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.payment-input-parent {
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.check-out-para p {
  font-size: 20px;
  font-family: Poppins-Regular;
  margin: 0;
  color: #707070;
}
.check-out-para p {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.check-out-para p a {
  display: block;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  color: var(--yellow-prime);
}

.checkout-page-btn a {
  text-decoration: none;
  background: var(--yellow-prime);
  border-radius: 19px !important;
  min-width: 412px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Poppins-SemiBold;
  color: var(--black-common);
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
.stripe-btn-parent button,
.stripe-btn-parent button span {
  text-decoration: none;
  background: var(--yellow-prime) !important;
  border-radius: 19px !important;
  min-width: 412px;
  max-width: 412px;
  min-height: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  font-family: Poppins-SemiBold !important;
  color: var(--black-common) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
.custom-payment-button button {
  border: none;
}
.checkout-page-btn a:hover,
.custom-payment-button button:hover {
  color: var(--black-common);
  border-color: var(--black-common);
  background: transparent;
}
.checkout-main-sec {
  padding: 2rem 0 3rem 0;
}
.checkout-content-parent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.checkout-page-btn {
  padding-top: 3rem;
}

/* checkout-page-styles */

/* thank-page-styles */
.thnkyou-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.thnkyou-btn a {
  max-width: 412px;
}
.thnkyou-btn a:hover {
  border-color: var(--white-common);
  color: var(--white-common);
}
.thank-banner {
  width: 100% !important;
}
.thank-banner .banner-para {
  width: 56%;
  margin: 0 auto;
}
/* thank-page-styles */

/* Project Tabs Styles */

.project-tabs-parent {
  display: grid;
  gap: 4%;
  grid-template-columns: 25% 71%;
  padding: 2rem 0 0 0;
}

.project-tabs-section {
  padding: 3rem 0;
  background: var(--yellow-prime);
}
.project-tabs-heading h2,
.serv-sec-two-heading h2 {
  font-family: Poppins-SemiBold;
  font-size: 64px;
  margin: 0;
  padding-bottom: 1.5rem;
  color: var(--white-common);
  border-bottom: 1px solid rgb(0, 0, 0, 30%);
}
.project-tabs-parent ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: none !important;
}

.project-tabs-parent ul li button {
  border-radius: 0 !important;
  color: var(--white-common);
  border: 3px solid var(--white-common) !important;
  font-family: Poppins-Medium;
  width: 100%;
  min-height: 87px;
  max-height: 130px;
}

.project-tabs-parent ul li button,
.project-tab-list p {
  font-size: 22px;
}

.project-tabs-parent ul li button:hover {
  border-radius: 0 !important;
}
.project-tabs-parent ul li button:hover {
  background: var(--black-common);
  color: var(--white-common);
}

.project-tabs-parent ul li .active {
  background: var(--black-common) !important;
  color: var(--white-common) !important;
  border-color: var(--black-common) !important;
  border-radius: 0 !important;
}

.project-tabs-parent ul li .nav-link:focus,
.project-tabs-parent ul li .nav-link:hover {
  border-color: transparent !important;
}

.project-tab-list p {
  font-family: Poppins-Regular;
  margin: 0;
  color: var(--black-common);
}

.project-tab-list p span {
  padding: 0 0.5rem 0 0;
}
.project-tab-right-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  margin: 0 0 0 auto;
}
.project-tabs-section .tab-content {
  background: #fff;
}

.project-tabs-section .tab-content > .active {
  display: block;
  background: #fff;
  max-height: 575px;
  overflow-y: scroll;
  width: 90%;
  margin: 2rem 0 1.5rem 0;
}
.project-tabs-section .tab-content > .active::-webkit-scrollbar {
  width: 6px;
}

.project-tabs-section .tab-content > .active ::-moz-scrollbar {
  width: 6px;
}

.project-tabs-section .tab-content > .active::-webkit-scrollbar-thumb {
  background-color: var(--yellow-prime);
}

.project-tabs-section .tab-content > .active::-moz-scrollbar-thumb {
  background-color: var(--yellow-prime);
}

/* Project Tabs Styles */

/* Services Page Styles */
.img-serv-one img {
  width: 100%;
}
.services-point ul li {
  position: relative;
  list-style: none;
  font-family: Poppins-SemiBold;
  font-size: 22px;
  color: var(--black-common);
}
.services-point ul {
  padding: 1rem 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/* .services-point ul li::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -2rem;
  background-image: url(../imgs/serv-list-arrow.png);
  background-repeat: no-repeat;
  background-position: left;
} */
.services-point ul li span {
  position: absolute;
  left: -1.5rem;
}

.serv-sec-two-img img {
  width: 100%;
}
.serv-sec-two-heading h2 {
  color: var(--yellow-prime);
}

.serv-sec-two-list ul li {
  font-size: 16px;
  font-family: Poppins-Regular;
  color: var(--black-common);
  position: relative;
  list-style: none;
}
.serv-sec-two-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.serv-sec-two-list ul li span {
  position: absolute;
  top: 0;
  left: -2rem;
}
.serv-sec-two-list {
  padding-top: 1rem;
}
.services-sec-two {
  padding: 4rem 0;
}
.service-sec-one {
  padding: 3rem 0;
}
/* Services Page Styles */

/* Contact-Page Styles */
.container-contact-custom {
  width: 69vw;
  margin: 0 auto;
}
.contact-heading h2 {
  font-size: 48px;
  font-family: Poppins-SemiBold;
  color: var(--black-common);
  margin: 0;
}
.contact-detail ul li {
  list-style: none;
  display: grid;
  gap: 2%;
  grid-template-columns: 5% 93%;
  font-size: 16px;
  font-family: Poppins-Regular;
  color: var(--black-common);
}
.contact-detail ul li a {
  color: var(--black-common);
}
.contact-detail ul {
  padding: 1.5rem 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact-detail ul li span svg {
  color: var(--yellow-prime);
  font-size: 21px;
}
.contact-input-parent input,
.contact-input-parent textarea {
  width: 100%;
  color: var(--black-common);
  font-size: 16px;
  font-family: Poppins-Regular;
  border: 2px solid #f1d36f;
  background: #eff2f2;
  outline: none;
  padding: 0 2%;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
.contact-input-parent textarea {
  min-height: 175px;
  padding-top: 0.5rem;
}
.contact-input-parent input {
  min-height: 55px;
}

.contact-input-parent input:focus,
.contact-input-parent textarea:focus {
  opacity: 1;
  border-color: var(--yellow-prime);
}

.contact-input-submt input {
  border: none;
  outline: none;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: var(--white-common);
  background: var(--yellow-prime);
  padding: 0.8rem 1.2rem;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem 0 0 0;
}
.contact-section {
  padding: 2rem 0 3rem 0;
}
/* Contact-Page Styles */

/* Privacy Policy Styles */
.heading-privacy h2 {
  font-size: 48px;
  font-family: Poppins-SemiBold;
  color: var(--black-common);
  margin: 0;
}

.privacy-policy-sec p,
.privacy-policy-sec li {
  font-family: Poppins-Regular;
}
.privacy-policy-sec h5,
.privacy-policy-sec span {
  font-family: Poppins-SemiBold;
}
.privacy-policy-sec h5,
.privacy-policy-sec span,
.privacy-policy-sec p,
.privacy-policy-sec li {
  font-size: 17px;
  color: #000000;
  margin: 0;
  word-break: break-all;
}
.txt-privacy-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem 0 3rem 0;
}

.privacy-banner {
  width: 100% !important;
}
/* Privacy Policy Styles */

/* Website Modal Styles */
.cancel-btn-cust-modal img {
  cursor: pointer;
}

.cust-modal-heading-web h3 {
  font-family: Poppins-SemiBold;
  font-size: 48px;
  color: var(--black-common);
  margin: 0 0 0.5rem 0;
}

.img-modal-custom-header img {
  min-width: 164px;
  max-width: 164px;
  padding: 0.7rem;
  background: var(--yellow-prime);
  border-radius: 16px;
}
.modal-input-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1.5rem 0;
}

.custom-web-modal-header {
  display: flex;
  align-items: center;
  gap: 30px;
}
.modal-product-website .modal-dialog {
  max-width: 61%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.modal-parent-web-review-parent {
  background: #fff;
}
.cancel-btn-cust-modal {
  position: absolute;
  right: -1rem;
  top: 1rem;
}

.modal-input-inner input,
.modal-input-inner textarea {
  background: #e9e9e9;
  border: 1px solid #fdde77;
  font-size: 20px;
  font-family: Poppins-Regular;
  color: #737373;
  width: 100%;
  padding: 0 1%;
  outline: none;
}
.modal-input-inner input {
  min-height: 68px;
}
.modal-input-inner textarea {
  min-height: 150px;
  padding-top: 1%;
  padding-bottom: 1%;
}

.web-modal-btm-para p {
  font-size: 15px;
  font-family: Poppins-Regular;
  color: var(--black-common);
  margin: 0;
}
.web-modal-btm-para a {
  font-size: 15px;
  font-family: Poppins-SemiBold;
  color: var(--yellow-prime);
}

.agree-btn-modal-web button {
  min-height: 54px;
  border-radius: 10px;
  background: var(--yellow-prime);
  color: var(--black-common);
  font-family: Bebas-Bold;
  border: none;
  outline: none;
  width: 100%;
}
.modal-parent-web-review-inner-parent {
  width: 92%;
  margin: 0 auto;
  padding: 3rem 0;
}
.web-modal-btm-para {
  padding-bottom: 1rem;
}
.agree-btn-modal-web {
  width: 65%;
  margin: 0 auto;
}
/* Website Modal Styles */

/* filter-modal-stars-style */
.star-box svg {
  font-size: 22px;
  color: var(--yellow-prime);
}

.star-box {
  display: flex;
  align-items: center;
  gap: 3px;
  border: 1px solid var(--black-common);
}

.btn-wrapper-filter {
  gap: 30px;
}
.star-boxes-parent {
  gap: 20px;
}
.star-box {
  display: flex;
  align-items: center;
  gap: 3px;
  border: 1px solid var(--black-common);
  padding: 0 0.5rem;
}
.star-box-clicked {
  background: rgb(74, 185, 0, 34%);
  border-color: rgb(74, 185, 0, 34%);
}
.star-box-clicked svg,
.star-box-clicked i {
  color: #2a9f00;
}

/* filter-modal-stars-style */

/* Addtional Changes 26-Sep */
.about-sec-button {
  display: flex;
  justify-content: start;
  align-items: center;
}
.about-sec-button a,
.about-sec-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 142px;
  min-height: 37px;
  background: var(--yellow-prime);
  color: var(--white-common);
  border-radius: 10px;
  font-size: 17px;
  border: 1px solid var(--yellow-prime);
  font-family: Poppins-Medium;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.about-sec-button button {
  border: none;
}
.about-sec-button a:hover,
.about-sec-button button:hover {
  background: var(--black-common);
  color: var(--yellow-prime);
  border-color: var(--black-common);
}

.our-speci-sub-head h2 {
  color: var(--yellow-prime);
}
.parent-of-heading-our-speci hr {
  padding: 0 0 1rem 0;
  opacity: 0.3;
  width: 98%;
  margin: 0 0 1rem auto;
}
.our-specializaton {
  padding: 3rem 0;
}
.our-specializaton-content-parent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.specia-img img {
  width: 100%;
}
.heading-expertise-row h2 {
  color: var(--black-common);
}
.yel-heading-exper-row {
  color: var(--yellow-prime) !important;
}

.para-expertise-row p {
  font-size: 16px;
  font-family: Poppins-Regular;
  color: #707070;
  margin: 0.5rem 0 0 0;
}

.content-parent-expertise-row {
  width: 90%;
  margin: 0 auto 0 0;
}

.Expertise-Point-sec {
  padding: 3rem 0;
}

.expertise-point-row-parent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.our-values-main img {
  width: 100%;
}

.our-values-sec,
.our-values-main {
  padding: 3rem 0;
}

.badge {
  position: absolute;
  top: -1rem;
  right: -0.5rem;
  font-size: 9px;
  z-index: 9999;
  background: red !important;
}
.copy-right-fo {
  background: var(--yellow-prime);
  padding: 1.5rem 0;
  margin-top: 1rem;
}

.copy-right-parent a {
  color: var(--white-common);
  font-family: Poppins-Regular;
  margin-right: 3rem;
  display: block;
}
footer {
  position: relative;
  z-index: 15;
}
/* Addtional Changes 26-Sep */

/* responsive requirements */
.pricing-star-and-add-to-cart-tab-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-to-cart-tab-btn button,
.add-to-cart-tab-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 151px;
  min-height: 36px;
  font-size: 17px;
  font-family: Poppins-SemiBold;
  border-radius: 18px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 3;
}

.related-products-sec .slick-dots li button:before {
  font-size: 16px;
  opacity: 1;
  color: #e5e5e5;
  border: 1px solid var(--yellow-prime);
  border-radius: 50%;
}
.related-products-sec .slick-dots .slick-active button:before {
  color: var(--yellow-prime);
  border: 1px solid var(--yellow-prime);
}
.related-products-sec .slick-dots {
  bottom: -55px;
}

.our-specializaton .slick-dots li button:before {
  font-size: 16px;
  opacity: 1;
  color: #e5e5e5;
  border: 1px solid var(--yellow-prime);
  border-radius: 50%;
}
.our-specializaton .slick-dots .slick-active button:before {
  color: var(--yellow-prime);
  border: 1px solid var(--yellow-prime);
}
.our-specializaton .slick-dots {
  bottom: -25px;
}

/* responsive requirements */

/* hamburger-styles */
.header-left {
  cursor: pointer;
  position: fixed;
  z-index: 9;
  top: 2rem;
}
.hamburger-icon {
  display: flex;
  flex-direction: column;
  min-width: 50px;
  cursor: pointer;
  transform: scale(0.7);
}
.hamburger-checkbox {
  opacity: 0;
  width: 100%;
  height: 48px;
  position: absolute;
}
.header-left span:nth-of-type(3) {
  width: 100%;
}
.header-left span:nth-of-type(2) {
  width: 50%;
}
.header-left span:nth-of-type(1) {
  width: 100%;
}

.hamburger-checkbox:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(131deg) translate(7px, 0px);
}
.hamburger-checkbox:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(44deg) translate(-7px, -14px);
}
.hamburger-checkbox:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(20px, -14px) rotatez(43deg);
}
.hamburger-icon span {
  background: var(--yellow-prime);
  height: 7px;
  margin: 5px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

/* hamburger-styles */

.privacy-overflow {
  overflow-x: hidden;
}
