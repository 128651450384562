/* .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  */
  .pageNumber{
    cursor: pointer;

}
.flex{
        display: flex;
    }
.activeb{
    color: orange;
}
.arrows{
    font-size: 14px;
    padding: 0px 9px;
    color: gray;

}
.arrows span{
    padding: 4px;
    cursor: pointer;

}
  /* .pagination span {
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .pagination .arrow {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination span.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
    border-radius: 50%;
  }
  
  .slider {
    width: 150px;
    height: 3px;
    background-color: #ccc;
    position: relative;
  }
  
  .slider-thumb {
    width: 20px;
    height: 20px;
    background-color: #007bff;
    position: absolute;
    top: -10px;
    left: 0;
    cursor: pointer;
    border-radius: 50%;
  }
   */