@media only screen and (max-width: 1536px) {
  .container-custom {
    width: 85vw;
  }

  /* banner-responsive */
  .inner-sidebar a {
    font-size: 16px;
  }

  .right-hang-circ span img {
    max-width: 22px;
    max-height: 22px;
  }

  .right-hang-circ span {
    padding: 0.5rem 0.55rem;
  }

  .free-call-hang-right h4,
  .free-call-hang-right a {
    font-size: 17px;
  }

  .sidebar {
    padding: 4rem 1.2rem;
  }

  .banner-heading h2 {
    font-size: 63px;
  }

  .banner-para p {
    font-size: 16px;
  }

  .banner-txt-parent {
    width: 40%;
  }

  .parent-top-logo {
    padding: 1.5rem 0 5rem 0;
  }

  .logo-banner-rev img {
    max-width: 142px;
  }

  /* banner-responsive */

  /* sec-one-ind-responsive */
  .ind-sec-one-heading h2,
  .sec-two-ind-heading h2,
  .slider-item-parent:before,
  .sec-three-heading h2,
  .heading-sec-four h2,
  .project-tabs-heading h2,
  .serv-sec-two-heading h2,
  .our-speci-sub-head h2,
  .heading-expertise-row h2 {
    font-size: 56px;
  }

  .ind-sec-one-para p,
  .para-expertise-row p {
    font-size: 13px;
  }

  .ind-sec-one-para {
    margin: 0.5rem 0 0.7rem 0;
  }

  .ind-sec-one-sub-head h3 {
    font-size: 29px;
  }

  .ind-sec-one-sub-head h4 {
    font-size: 24px;
  }

  .ind-sec-one-sub-head hr {
    margin: 0.7rem 0;
  }

  .exp-right-ind-one h3 {
    font-size: 19px;
    padding: 0 0.7rem;
  }

  /* sec-one-ind-responsive */

  /* sec-two-ind-responsive */
  .sec-two-ind {
    padding: 1rem 0 3rem 0;
  }

  .slider-item-parent-one::after,
  .slider-item-parent-two::after,
  .slider-item-parent-three::after {
    font-size: 25px;
  }

  /* sec-two-ind-responsive */

  /* sec-three-ind-responsive */
  .sec-three-ind {
    padding: 3rem 0;
  }

  .descrip-heading h3,
  .descrip-amount h5 {
    font-size: 24px;
  }

  .star-descrip svg {
    font-size: 16px;
  }

  .descrip-amount h5 {
    margin: 0 0 1.5rem 0;
  }

  .descrip-btn-parent a,
  .descrip-btn-parent button {
    min-width: 131px;
    min-height: 35px;
    font-size: 16px;
  }

  /* sec-three-ind-responsive */

  /* sec-four-ind-responsive */
  .heading-sec-four h5 {
    font-size: 22px;
  }

  /* sec-four-ind-responsive */

  /* footer-responsive */
  .fo-heading h3 {
    font-size: 21px;
  }

  .fo-desc-parent {
    gap: 8px;
    margin: 1.5rem 0 0 0;
  }

  .fo-desc span,
  .fo-desc a {
    font-size: 16px;
  }

  .fo-desc svg {
    font-size: 16px;
  }

  .fo-form form {
    gap: 12px;
    margin: 1.5rem 0 0 0;
  }

  .fo-field,
  .fo-textarea {
    font-size: 14px;
    padding: 0.6rem 1rem;
  }

  .submit-btn-fo {
    max-width: 135px;
    min-height: 41px;
    font-size: 15px;
  }

  .fo-textarea {
    min-height: 120px;
  }

  .copy-right-parent {
    max-width: 40rem;
    min-height: 52px;
    margin: 0;
  }

  .copy-right-parent p {
    font-size: 12px;
  }

  .logo-fo img {
    width: 48%;
  }

  /* footer-responsive */

  /* modal-responsive */
  .img-modal-cancel img {
    max-width: 22px;
  }

  /* modal-responsive */

  /* Shop-Page Responsive */
  .shop-main-row-parent .row .col-lg-4 {
    padding: 2rem 0;
  }

  .show-result p {
    font-size: 18px;
  }

  .search-bar input,
  .sorting-drp-down select {
    min-height: 42px;
  }

  .search-bar input {
    font-size: 18px;
  }

  .search-bar button svg {
    font-size: 23px;
  }

  .search-bar button {
    transform: translate(-1px, 2px);
    padding: 0 0.3rem;
    min-height: 44px;
  }

  .sorting-drp-down select {
    font-size: 16px;
  }

  .sorting-drp-down select option {
    font-size: 13px;
  }

  .readmore a {
    max-width: 141px;
    min-height: 35px;
    font-size: 16px;
    border-radius: 15px;
  }

  /* Shop-Page Responsive */

  /* Single-Product-Responsive */
  .product-web-heading-main h2 {
    font-size: 40px;
  }

  .rating > label {
    width: 40px;
  }

  .single-product-price h4 {
    font-size: 33px;
  }

  .single-product-top-description h4 {
    font-size: 18px;
    margin-bottom: 0.3rem;
  }

  .single-product-top-description p {
    font-size: 14px;
  }

  .add-minus-btns {
    font-size: 20px;
  }

  .quantity-heading-prdt h5 {
    font-size: 16px;
  }

  .input-add-prdct {
    font-size: 24px;
  }

  .product-web-top-sec-btn a,
  .product-web-top-sec-btn button {
    font-size: 13px;
    padding: 0.35rem 0.7rem;
  }

  .product-web-main-right {
    gap: 10px;
  }

  .review-sec-heading h2 {
    font-size: 27px;
    padding-bottom: 0.7rem;
  }

  .btn-product-review-parent button {
    border-radius: 8px;
    padding: 0.2rem 1.2rem;
    font-size: 17px;
    gap: 3px;
  }

  .btn-product-review-parent button img {
    max-width: 14px;
  }

  .para-rev,
  .rating-star-customer-rev svg {
    font-size: 17px;
  }

  .recent-most select {
    min-width: 106px;
    max-width: 106px;
    font-size: 13px;
  }

  .review-sub-sort {
    padding-bottom: 0.7rem;
  }

  .review-web-sec-content-parent,
  .gived-reviews-parent {
    gap: 23px;
  }

  .customer-date {
    font-size: 12px;
  }

  .customer-review-star-parent {
    gap: 7px;
  }

  .customer-desc-para {
    font-size: 14px;
  }

  /* Single-Product-Responsive */

  /* Cart-Page-Responsive */
  .grid-cart-inner p {
    font-size: 27px;
  }

  .cart-resuse-text {
    font-size: 23px;
  }

  .cart-product-description img {
    min-width: 70px;
    max-width: 70px;
    padding: 0.4rem;
  }

  .total-cart-two {
    font-size: 23px;
  }

  .total-cart-one {
    font-size: 27px;
  }

  .total-cart-page {
    gap: 13px;
    padding-top: 1.5rem;
  }

  .cart-cancel-icon img {
    max-width: 19px;
  }

  .cart-btn-parent {
    padding: 8rem 0 0 0;
  }

  .cart-btn-parent a {
    font-size: 14px;
    gap: 7px;
    min-width: 205px;
    min-height: 46px;
  }

  /* Cart-Page-Responsive */

  /* Checkout-Page-Responsive */
  .checkout-inner label {
    font-size: 17px;
  }

  .checkout-inner {
    gap: 5px;
  }

  .checkout-inner input {
    min-height: 37px;
  }

  .checkout-inner input,
  .checkout-inner textarea {
    font-size: 14px;
  }

  .checkout-inner label sup {
    padding: 0.7rem 0 0.7rem 0;
    font-size: 11px;
  }

  .order-sum-heading h2 {
    font-size: 30px;
  }

  .order-summary-parent {
    gap: 40px;
  }

  .payment-detail-box-parent {
    border-radius: 17px;
    padding: 1.7rem;
  }

  .payment-deatil-heading h3 {
    font-size: 28px;
  }

  .payment-input label {
    font-size: 17px;
  }

  .payment-input input {
    font-size: 13px;
    min-height: 36px;
  }

  .check-out-para p {
    font-size: 17px;
  }

  .check-out-para p a {
    font-size: 15px;
  }

  .checkout-page-btn {
    padding-top: 2rem;
  }

  .checkout-page-btn a,
  .stripe-btn-parent button,
  .stripe-btn-parent button span {
    border-radius: 17px !important;
    min-width: 342px;
    min-height: 39px !important;
    font-size: 13px !important;
  }

  .checkout-content-parent {
    gap: 20px;
  }

  /* Checkout-Page-Responsive */

  /* Thank-Page-Responsive */
  .thnkyou-btn a {
    max-width: 300px;
    min-width: 300px;
    min-height: 38px;
  }

  .thank-banner .banner-para {
    width: 48%;
  }

  /* Thank-Page-Responsive */

  /* Service-Page-Styles Responsive */
  .services-point ul li {
    font-size: 18px;
  }

  .services-point ul li span img {
    max-width: 12px;
  }

  .services-point ul li span {
    left: -1.3rem;
  }

  .serv-sec-two-list ul li {
    font-size: 13px;
  }

  .serv-sec-two-list ul li span img {
    max-width: 12px;
  }

  .serv-sec-two-list ul li span {
    left: -1.5rem;
  }

  .serv-sec-two-list ul {
    gap: 15px;
  }

  .services-sec-two {
    padding: 3rem 0;
  }

  .service-sec-one {
    padding: 2rem 0;
  }

  /* Service-Page-Styles Responsive */

  /* Contact-Page Responsive */
  .contact-heading h2 {
    font-size: 38px;
  }

  .contact-detail ul {
    padding: 1rem 0 0 0;
    gap: 10px;
  }

  .contact-detail ul li span svg {
    font-size: 18px;
  }

  .contact-detail ul li span img {
    max-width: 18px;
  }

  .contact-detail ul li {
    font-size: 14px;
  }

  .contact-form {
    gap: 17px;
    padding: 1.5rem 0 0 0;
  }

  .contact-input-parent input {
    min-height: 45px;
  }

  .contact-input-parent input,
  .contact-input-parent textarea {
    font-size: 14px;
  }

  .contact-input-parent textarea {
    min-height: 135px;
    padding-top: 0.4rem;
  }

  .contact-input-submt input {
    font-size: 14px;
    padding: 0.6rem 1rem;
  }

  .contact-section {
    padding: 1rem 0 3rem 0;
  }

  /* Contact-Page Responsive */
  /* Privacy-Page-Styles */
  .privacy-policy-sec h5,
  .privacy-policy-sec span,
  .privacy-policy-sec p,
  .privacy-policy-sec li {
    font-size: 14px;
  }

  .txt-privacy-parent {
    gap: 15px;
    padding: 1rem 0 3rem 0;
  }

  .heading-privacy h2 {
    font-size: 38px;
  }

  /* Privacy-Page-Styles */

  /* Project Tab-Styles */
  .project-tabs-parent ul li button,
  .project-tab-list p {
    font-size: 18px;
  }

  .project-tabs-parent ul li button {
    min-height: 64px;
    border: 2px solid var(--white-common) !important;
  }

  .project-tab-right-inner {
    gap: 15px;
  }

  .project-tabs-section .tab-content > .active {
    max-height: 420px;
    width: 90%;
    margin: 1.5rem 0 0.5rem 0;
  }

  /* Project Tab-Styles */

  /* Web-Modal-RESPONSIVE */
  .cust-modal-heading-web h3 {
    font-size: 38px;
    margin: 0 0 0.3rem 0;
  }
  .img-modal-custom-header img {
    min-width: 134px;
    max-width: 134px;
    padding: 0.5rem;
    border-radius: 13px;
  }
  .modal-input-parent {
    gap: 15px;
    padding: 1rem 0;
  }
  .modal-input-inner input,
  .modal-input-inner textarea {
    font-size: 16px;
  }
  .modal-input-inner input {
    min-height: 50px;
  }
  .modal-input-inner textarea {
    min-height: 110px;
  }
  .modal-parent-web-review-inner-parent {
    padding: 2rem 0;
  }
  .web-modal-btm-para p,
  .web-modal-btm-para a {
    font-size: 12px;
  }
  .agree-btn-modal-web button {
    min-height: 44px;
    border-radius: 7px;
    font-size: 14px;
  }
  /* Web-Modal-RESPONSIVE */

  /* Filter-btn-Main-Website-Responsive */
  .btn-wrapper-filter {
    gap: 20px;
  }
  .star-boxes-parent {
    gap: 15px;
  }
  .star-box svg {
    font-size: 18px;
  }
  .star-box {
    padding: 0 0.4rem;
  }
  /* Filter-btn-Main-Website-Responsive */

  /* Addional Changes 25-sep-Responsive */
  .about-sec-button a,
  .about-sec-button button {
    min-width: 122px;
    min-height: 31px;
    border-radius: 8px;
    font-size: 15px;
  }
  .parent-of-heading-our-speci hr {
    padding: 0 0 0.7rem 0;
  }
  .img-sec-three-ind img {
    height: 400px;
  }
  /* Addional Changes 25-sep-Responsive */
}

@media only screen and (max-width: 1366px) {
  ::-webkit-scrollbar {
    width: 9px;
  }

  /* banner-responsive */
  .inner-sidebar a {
    font-size: 14px;
  }

  .sidebar {
    padding: 3.5rem 1rem;
  }

  .free-call-hang-right h4,
  .free-call-hang-right a {
    font-size: 13px;
  }

  .right-hang-circ span {
    padding: 0.25rem 0.45rem 0.35rem 0.45rem;
  }

  .right-hang-circ span img {
    max-width: 18px;
    max-height: 18px;
  }

  .right-hang-banner-parent {
    top: 25%;
  }

  .right-hang-circ-parent {
    gap: 10px;
  }

  .banner-heading h2 {
    font-size: 43px;
  }

  .banner-txt-parent {
    width: 31%;
  }

  .banner-para p {
    font-size: 10px;
  }

  .logo-banner-rev img {
    max-width: 110px;
  }

  /* banner-responsive */
  /* sec-one-ind-responsive */
  .ind-sec-one-heading h2,
  .sec-two-ind-heading h2,
  .slider-item-parent:before,
  .sec-three-heading h2,
  .heading-sec-four h2,
  .project-tabs-heading h2,
  .serv-sec-two-heading h2,
  .our-speci-sub-head h2,
  .heading-expertise-row h2 {
    font-size: 40px;
  }

  .ind-sec-one-para p,
  .para-expertise-row p {
    font-size: 11px;
  }

  .ind-sec-one-para {
    margin: 0.3rem 0 0.6rem 0;
  }

  .ind-sec-one-sub-head h3 {
    font-size: 24px;
  }
  .ind-sec-one-sub-head h4 {
    font-size: 22px;
  }

  .exp-right-ind-one h3 {
    font-size: 15px;
  }

  .sec_one-index {
    padding: 1rem 0;
  }

  /* sec-one-ind-responsive */

  /* sec-two-ind-responsive */
  .sec-two-ind-heading {
    padding: 1rem 0 1rem 0;
  }

  /* sec-two-ind-responsive */

  /* sec-three-ind-responsive */
  .sec-three-ind {
    padding: 2rem 0;
  }

  .sec-three-row-parent {
    padding: 2rem 0 0 0;
  }

  .img-sec-three-ind img,
  .product-web-left-img img {
    padding: 1.5rem;
  }

  .descrip-heading h3,
  .descrip-amount h5 {
    font-size: 19px;
  }

  .star-descrip svg {
    font-size: 14px;
  }

  .descrip-heading h3 {
    margin: 0;
  }

  .descrip-btn-parent a,
  .descrip-btn-parent button {
    min-width: 103px;
    min-height: 27px;
    font-size: 12px;
  }
  /* sec-three-ind-responsive */

  /* sec-four-ind-responsive */
  .heading-sec-four h5 {
    font-size: 16px;
  }

  .heading-sec-four h2 {
    margin-bottom: 0.5rem;
  }

  .sec-four-ind {
    padding: 1.5rem 0;
  }

  .sec-four-ind .slick-track {
    gap: 25px;
    padding: 0rem 0 1rem 0;
  }

  .slider-ins-parent {
    padding: 1rem 0 2rem 0;
  }

  /* sec-four-ind-responsive */
  /* footer-responsive  */
  .fo-heading h3 {
    font-size: 18px;
  }

  .fo-desc-parent,
  .fo-form form {
    margin: 1rem 0 0 0;
    gap: 3px;
  }

  .fo-desc svg {
    font-size: 12px;
  }

  .fo-desc span,
  .fo-desc a {
    font-size: 12px;
  }

  .fo-form form {
    gap: 10px;
  }

  .fo-field,
  .fo-textarea {
    font-size: 12px;
    padding: 0.4rem 0.7rem;
  }

  .fo-textarea {
    min-height: 90px;
  }

  .submit-btn-fo {
    max-width: 110px;
    min-height: 32px;
    font-size: 12px;
    border-radius: 7px;
  }

  .copy-right-parent {
    max-width: 32rem;
    min-height: 42px;
    padding: 0 0 0 1.5rem;
  }
  .copy-right-parent p {
    font-size: 10px;
  }

  /* footer-responsive  */

  /* modal-responsive  */
  .img-modal-cancel img {
    max-width: 17px;
  }

  /* modal-responsive  */

  /* Shop-Page Styles  */
  .shop-main-row-parent .row .col-lg-4 {
    padding: 1.5rem 0;
  }

  .show-result p {
    font-size: 14px;
  }

  .search-bar input {
    font-size: 15px;
  }

  .search-bar input,
  .sorting-drp-down select {
    min-height: 36px;
  }

  .search-bar input {
    border-radius: 20px 0 0 20px;
  }

  .search-bar button {
    border-radius: 0 20px 20px 0;
  }

  .search-bar button svg {
    font-size: 19px;
  }

  .search-bar button {
    min-height: 37px;
  }

  .sorting-drp-down select {
    font-size: 13px;
  }

  .sorting-drp-down select option {
    font-size: 10px;
  }

  .shop-section {
    padding-bottom: 3.5rem;
  }

  .readmore a {
    max-width: 111px;
    min-height: 29px;
    font-size: 13px;
    border-radius: 14px;
  }

  /* Shop-Page Styles  */

  /* Single-Product-Responsive */
  .product-web-heading-main h2 {
    font-size: 33px;
  }

  .rating > label {
    width: 27px;
    height: 27px;
  }

  .single-product-price h4 {
    font-size: 28px;
  }

  .single-product-top-description h4 {
    font-size: 14px;
    margin-bottom: 0.2rem;
  }

  .single-product-top-description p {
    font-size: 11px;
  }

  .input-add-prdct {
    font-size: 18px;
  }

  .add-minus-btns {
    font-size: 17px;
  }

  .quantity-heading-prdt h5 {
    font-size: 14px;
  }

  .product-web-main-right {
    gap: 8px;
  }

  .product-web-top-sec-btn a,
  .product-web-top-sec-btn button {
    font-size: 11px;
    padding: 0.3rem 0.6rem;
  }

  .review-sec-heading h2 {
    font-size: 22px;
    padding-bottom: 0.6rem;
  }

  .review-web-sec-content-parent,
  .gived-reviews-parent {
    gap: 18px;
  }

  .btn-product-review-parent button {
    border-radius: 7px;
    padding: 0.2rem 1rem;
    font-size: 14px;
    gap: 5px;
  }

  .para-rev,
  .rating-star-customer-rev svg {
    font-size: 14px;
  }

  .btn-product-review-parent button img {
    max-width: 12px;
  }

  .most-recent-parent {
    gap: 13px;
  }

  .recent-most select {
    min-width: 96px;
    max-width: 96px;
    font-size: 12px;
  }

  .review-sub-sort {
    padding-bottom: 0.5rem;
  }

  .product-review-web {
    padding: 2.5rem 0;
  }

  .customer-date {
    font-size: 10px;
  }

  .rating-star-customer-rev {
    gap: 3px;
  }

  .customer-review-star-parent {
    gap: 6px;
  }

  .customer-desc-para {
    font-size: 12px;
  }

  .related-products-sec {
    padding: 2rem 0 3rem 0;
  }

  /* Single-Product-Responsive */

  /* Cart-Page-Responive */

  .cart-resuse-text {
    font-size: 19px;
  }

  .cart-product-description img {
    min-width: 60px;
    max-width: 60px;
  }

  .grid-cart-inner p {
    font-size: 22px;
  }

  .grid-cart-super-parent {
    gap: 10px;
  }

  .total-cart-one {
    font-size: 22px;
  }

  .total-cart-two {
    font-size: 19px;
  }

  .total-cart-page {
    gap: 10px;
    padding-top: 1.2rem;
  }

  .cart-cancel-icon img {
    max-width: 16px;
  }

  .cart-btn-parent a {
    font-size: 12px;
    gap: 6px;
    min-width: 175px;
    min-height: 40px;
  }

  /* Cart-Page-Responive */

  /* Checkout-page-responsive */
  .checkout-inner label {
    font-size: 15px;
  }

  .checkout-inner input,
  .checkout-inner textarea {
    font-size: 13px;
  }

  .checkout-inner input {
    min-height: 32px;
  }

  .checkout-inner textarea {
    min-height: 60px;
  }

  .checkout-inner label sup {
    padding: 0.5rem 0 0.5rem 0;
    font-size: 9px;
  }

  .order-sum-heading h2 {
    font-size: 25px;
  }

  .payment-deatil-heading h3 {
    font-size: 20px;
  }

  .payment-detail-box-parent {
    border-radius: 15px;
    padding: 1.5rem;
  }

  .payment-input-parent {
    padding: 1rem 0 0 0;
    gap: 15px;
  }

  .payment-input label {
    font-size: 14px;
  }

  .payment-input input {
    font-size: 11px;
    min-height: 30px;
  }

  .checkout-content-parent {
    gap: 14px;
  }

  .check-out-para p {
    font-size: 14px;
  }

  .check-out-para p a {
    font-size: 13px;
  }

  .check-out-para p {
    gap: 3px;
  }

  .checkout-page-btn a,
  .stripe-btn-parent button,
  .stripe-btn-parent button span {
    border-radius: 15px !important;
    min-width: 262px;
    min-height: 31px !important;
    font-size: 11px !important;
  }

  /* Checkout-page-responsive */

  /* Thank-page-responsive */
  .thnkyou-btn a {
    max-width: 200px;
    min-width: 200px;
    min-height: 27px;
    font-size: 10px;
  }

  .thnkyou-btn {
    padding-top: 1rem;
  }

  .thank-banner .banner-para {
    width: 36%;
  }

  /* Thank-page-responsive */

  /* Services-page-Responsive */
  .services-point ul li {
    font-size: 15px;
  }

  .services-point ul li span img {
    max-width: 10px;
  }

  .services-point ul li span {
    left: -1rem;
  }

  .services-point ul {
    padding: 0.7rem 0 0 0;
    gap: 14px;
  }

  .project-tabs-heading h2,
  .serv-sec-two-heading h2 {
    padding-bottom: 1rem;
  }
  .serv-sec-two-list ul {
    gap: 11px;
  }
  .serv-sec-two-list ul li {
    font-size: 12px;
  }
  .serv-sec-two-list {
    padding-top: 0.7rem;
  }

  /* Services-page-Responsive */

  /* Contact-Page Styles  */
  .contact-heading h2 {
    font-size: 33px;
  }

  .contact-detail ul li span svg {
    font-size: 16px;
  }

  .contact-detail ul li span img {
    max-width: 16px;
  }

  .contact-detail ul li {
    font-size: 12px;
  }

  .contact-form {
    gap: 14px;
    padding: 1.2rem 0 0 0;
  }

  .contact-input-parent input,
  .contact-input-parent textarea {
    font-size: 11px;
  }

  .contact-input-parent input {
    min-height: 38px;
  }

  .contact-input-parent textarea {
    min-height: 105px;
    padding-top: 0.3rem;
  }

  .contact-input-submt input {
    font-size: 12px;
    padding: 0.5rem 0.8rem;
  }

  /* Contact-Page Styles  */

  /* Privacy-Page Responsive */
  .txt-privacy-parent {
    gap: 12px;
    padding: 1rem 0 3rem 0;
  }

  .privacy-policy-sec h5,
  .privacy-policy-sec span,
  .privacy-policy-sec p,
  .privacy-policy-sec li {
    font-size: 12px;
  }

  .heading-privacy h2 {
    font-size: 33px;
  }

  /* Privacy-Page Responsive */

  /* Project Tab Responsive */
  .project-tabs-parent ul li button {
    min-height: 54px;
  }

  .project-tabs-parent ul li button,
  .project-tab-list p {
    font-size: 13px;
  }
  .project-tabs-section .tab-content > .active {
    max-height: 400px;
    width: 90%;
    margin: 1rem 0 0.5rem 0;
  }
  .project-tab-list p span img {
    max-width: 12px;
  }

  /* Project Tab Responsive */

  /* Modal-Responisve-WEb */
  .img-modal-custom-header img {
    min-width: 100px;
    max-width: 100px;
    padding: 0.4rem;
    border-radius: 10px;
  }
  .custom-web-modal-header {
    gap: 20px;
  }
  .cust-modal-heading-web h3 {
    font-size: 30px;
    margin: 0 0 0.2rem 0;
  }
  .modal-input-parent {
    gap: 10px;
    padding: 0.7rem 0;
  }
  .modal-input-inner input {
    min-height: 40px;
  }
  .modal-input-inner input,
  .modal-input-inner textarea {
    font-size: 12px;
  }
  .modal-input-inner textarea {
    min-height: 90px;
  }
  .cancel-btn-cust-modal img {
    cursor: pointer;
    max-width: 15px;
  }
  .web-modal-btm-para p,
  .web-modal-btm-para a {
    font-size: 10px;
  }
  .web-modal-btm-para {
    padding-bottom: 0.5rem;
  }
  .agree-btn-modal-web button {
    min-height: 36px;
    border-radius: 5px;
    font-size: 12px;
  }
  .modal-parent-web-review-inner-parent {
    padding: 1.5rem 0;
  }
  /* Modal-Responisve-WEb */

  /* Filter-btn-Main-Website-Responsive */
  .star-box svg {
    font-size: 15px;
  }
  /* Filter-btn-Main-Website-Responsive */

  /* Addional Changes 25-sep-Responsive */
  .about-sec-button a,
  .about-sec-button button {
    min-width: 100px;
    min-height: 26px;
    border-radius: 6px;
    font-size: 12px;
  }
  .parent-of-heading-our-speci hr {
    padding: 0 0 0.25rem 0;
  }
  .expertise-point-row-parent {
    gap: 30px;
  }

  .our-values-sec {
    padding: 1rem 0 2rem 0;
  }

  .our-values-main {
    padding: 2rem 0;
  }
  .img-sec-three-ind img {
    height: 350px;
  }
  .copy-right-parent a {
    font-size: 12px;
  }
  /* Addional Changes 25-sep-Responsive */
}

@media only screen and (max-width: 999px) {
  .logo-fo img {
    width: 30%;
  }
  .fo-mid-sup-parent {
    padding: 0 44px;
  }
  .container-custom {
    padding-left: 2rem;
  }
  .sidebar {
    padding: 7.5rem 0.8rem;
  }
  .banner-txt-parent {
    width: 45%;
    padding-top: 10rem;
  }
  .right-hang-banner-content {
    flex-direction: column;
    align-items: end;
  }
  .free-call-hang-right {
    text-align: center;
  }
  .right-hang-circ-parent {
    flex-direction: column;
    margin-right: 1.7rem;
    margin-bottom: 0.5rem;
  }
  .sec-one-right-parent {
    width: 70%;
    margin: 0 auto;
  }

  .content-parent-sec-one-ind {
    text-align: center;
    padding-bottom: 2rem;
  }
  .about-sec-button {
    justify-content: center;
  }
  .slider-item-parent-one::after,
  .slider-item-parent-two::after,
  .slider-item-parent-three::after {
    font-size: 18px;
  }
  .ind-sec-one-heading h2,
  .sec-two-ind-heading h2,
  .slider-item-parent:before,
  .sec-three-heading h2,
  .heading-sec-four h2,
  .project-tabs-heading h2,
  .serv-sec-two-heading h2,
  .our-speci-sub-head h2,
  .heading-expertise-row h2 {
    font-size: 35px;
  }
  .img-sec-three-ind-mobile-slide {
    width: 55%;
  }
  .img-sec-three-ind img {
    height: 420px;
  }
  .sec-three-ind {
    padding: 3rem 0;
  }
  .sec-two-ind {
    padding: 2rem 0 5rem 0;
  }
  .sec_one-index {
    padding: 2rem 0 4rem 0;
  }
  .our-specializaton-content-parent {
    gap: 50px;
  }
  .Expertise-Point-sec {
    padding: 1rem 0 2rem 0;
  }
  .content-parent-expertise-row {
    margin: 0 auto 2rem 0;
  }
  .copy-right-fo {
    padding: 0.9rem 0;
    margin-top: 0.7rem;
  }
  .copy-right-fo a {
    font-size: 14px;
  }
  .logo-fo {
    border-right: none;
  }
  .our-values-main-resp-img {
    width: 55% !important;
    margin: 0 auto;
  }
  .show-result,
  .sorting-drp-down {
    text-align: center;
  }
  .search-row-gap-tab {
    gap: 1rem;
  }
  .search-bar button {
    transform: translate(-31px, 1px);
  }
  .sorting-drp-down select {
    max-width: 20rem;
  }
  .shop-main-row-parent {
    padding: 1.5rem 0 1rem 0;
  }
  .img-sec-three-ind:hover .descrip-sec-three-img-parent,
  .img-sec-three-ind:hover::before {
    opacity: 0;
  }
  .tab-descripiton-resp {
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #666666;
    margin-top: 0.4rem;
  }
  .tab-descripiton-resp-parent {
    width: 81%;
    margin: 0 auto;
  }
  .img-sec-three-ind img {
    padding: 1.1rem;
  }
  .shop-section {
    padding-top: 2rem;
  }
  .content-serv-first-row {
    width: 95%;
    margin: 0 auto;
  }
  .img-serv-one img {
    width: 90%;
  }
  .img-serv-one {
    text-align: center;
  }
  .services-point {
    padding-left: 3rem;
  }
  .row-parent-serv-gap-tab {
    gap: 3rem;
  }
  .serv-sec-two-row-parent {
    width: 90%;
    margin: 0 auto;
  }
  .exp-right-ind-one {
    padding: 3rem 0;
    top: 1rem;
    left: 5rem;
  }
  .serv-sec-two-img img {
    width: 95%;
  }
  .serv-sec-two-img {
    text-align: center;
  }
  .serv-sec-two-content-parent {
    padding-left: 2.5rem;
    padding-top: 1rem;
  }
  .contact-heading {
    padding-top: 1.5rem;
  }
  .star-descrip p {
    color: #666666 !important;
  }
  .services-point ul {
    padding: 2rem 0 0 0;
    gap: 18px;
  }
  .txt-privacy-parent {
    gap: 14px;
    padding: 2rem 0 3rem 0;
  }
  .product-web-left-img {
    width: 100%;
  }
  .product-web-left-img img {
    height: 33rem;
  }
  .product-web-left-img img {
    padding: 2rem;
  }
  .row-parent-product-web,
  .review-web-sec-content-parent,
  .related-pr-content-parent {
    width: 90%;
    margin: 0 auto;
  }
  .product-web-main-right-parent {
    padding-top: 1.7rem;
  }
  .single-product-top-description p {
    font-size: 13px;
  }
  .single-product-top-description {
    padding-bottom: 1rem;
  }
  .input-add-prdct {
    width: 70%;
  }
  .product-web-main-right {
    gap: 12px;
  }
  .star-box {
    gap: 2px;
  }
  .btn-wrapper-filter {
    gap: 10px;
  }
  .customer-desc-para {
    word-break: break-all;
  }
  .star-boxes-parent {
    gap: 6px;
  }
  .star-box svg {
    font-size: 13px;
  }
  .review-sec-heading h2 {
    font-size: 20px;
  }
  .product-web-sec {
    padding-top: 2rem;
  }
  .cancel-btn-cust-modal {
    right: -0.2rem;
    top: 1.2rem;
  }
  .img-modal-custom-header img {
    height: 8rem;
  }
  .modal-input-inner input {
    min-height: 36px;
  }
  .modal-input-inner textarea {
    min-height: 80px;
  }
  .modal-input-inner input,
  .modal-input-inner textarea {
    padding: 0 0.8rem;
  }
  .review-sec-heading h2 {
    padding-bottom: 0.55rem;
  }
  .related-pr-resp-img img {
    width: 85%;
    margin: 0 auto;
  }
  .related-products-sec {
    padding: 2rem 0 5.5rem 0;
  }
  .single-product-top-description h4 {
    font-size: 18px;
  }
  .single-product-top-description p {
    font-size: 14px;
  }
  .order-summary-parent {
    width: 100%;
    margin: 1rem 0;
  }
  .checkout-inner {
    margin-bottom: 1.3rem;
    gap: 8px;
  }
  .checkout-inner label sup {
    font-size: 11px;
  }
  .checkout-inner input {
    min-height: 36px;
  }
  .stripe-btn-parent .container {
    padding-left: 0;
    padding-right: 0;
  }
  .stripe-btn-parent button {
    width: 100%;
    max-width: 100%;
  }
  .stripe-btn-parent {
    margin: 1.2rem 0 0 0;
  }
  .checkout-page-btn a,
  .stripe-btn-parent button,
  .stripe-btn-parent button span {
    min-height: 35px !important;
  }
  .checkout-content-parent {
    width: 90%;
    margin: 0 auto;
  }
  .container-custom-cart {
    width: 65%;
  }
  .checkout-main-sec {
    padding: 2rem 0 4rem;
  }
  .privacy-policy-sec h5,
  .privacy-policy-sec span,
  .privacy-policy-sec p,
  .privacy-policy-sec li {
    font-size: 14px;
  }
  .sec-three-ind .slick-dots {
    width: 40%;
  }
  .sec-two-ind .slick-dots {
    width: 65%;
    left: 6rem;
    bottom: -3rem;
  }
  .sec-two-ind .slick-active button:before {
    height: 6px;
  }
  .copy-right-parent {
    max-width: 45rem;
  }
  .copy-right-parent2 {
    width: 100%;
  }
  .login-box {
    width: 70% !important;
  }
}

@media only screen and (max-width: 767px) {
  .container-custom {
    padding-left: 0;
  }
  .logo-banner-rev img {
    max-width: 90px;
  }
  .right-hang-circ span {
    padding: 0.3rem 0.45rem 0.25rem 0.45rem;
  }
  .parent-top-logo {
    padding: 1.5rem 0 2rem 0;
  }
  .img-sec-three-ind-mobile-slide {
    width: 85%;
  }
  .banner-txt-parent {
    width: 85%;
  }
  .search-bar button {
    transform: translate(-13px, 1px);
  }
  .exp-right-ind-one {
    padding: 0;
    top: 4rem;
    left: 3rem;
  }
  .exp-right-ind-one h3 {
    font-size: 11px;
    padding: 0 0.3rem;
  }
  .sec-two-ind-heading {
    width: 50%;
    margin: 0 auto;
    padding: 0rem 0 2rem 0;
    text-align: center;
  }

  .sec-two-ind .slick-dots {
    width: 85%;
    left: 1rem;
    bottom: -3rem;
  }
  .sec-two-ind .slick-active button:before {
    height: 8px;
  }
  .sec-two-ind .slick-dots li {
    margin: 0 0px 4px 0;
  }
  .sec-two-ind .slick-dots li.slick-active button:before {
    left: 2px;
  }
  .sec-two-ind {
    padding: 2rem 0 6rem 0;
  }
  .sec-three-heading {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
  .sidebar {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
  .sidebar-active {
    opacity: 1;
  }
  .sec-three-ind .slick-dots {
    width: 60%;
  }
  .our-values-main-resp-img {
    width: 68% !important;
  }
  .our-values-sec {
    padding: 3rem 0 2.5rem 0;
  }
  .logo-fo {
    text-align: center;
  }
  .fo-mid-sup-parent {
    padding: 1.5rem 0 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .fo-form {
    width: 100%;
  }
  footer {
    padding: 2rem 0 5rem 0;
  }
  .copy-right-parent {
    max-width: 32rem;
    min-width: 23rem;
    padding: 0 0 0 1rem;
  }
  .logo-fo img {
    width: 35%;
  }
  .row-parent-footer {
    padding: 0 1rem;
  }
  .fo-desc-parent,
  .fo-form form {
    margin: 0.5rem 0 0 0;
  }
  .nav-item {
    width: 60%;
  }
  .project-tabs-parent ul {
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: center;
  }
  .project-tabs-parent {
    display: flex;
    padding: 2rem 0 0 0;
    flex-wrap: wrap;
  }
  .project-tabs-section .tab-content {
    width: 100%;
  }
  .project-tabs-section {
    padding: 1.5rem 0 3rem 0;
  }
  .services-point {
    padding-left: 2rem;
  }
  .container-contact-custom {
    width: 75vw;
  }
  .contact-form .col-6 {
    width: 100%;
  }
  .contact-input-last-name-mob-gap {
    padding-top: 1rem;
  }
  .product-web-left-img img {
    padding: 1.3rem;
  }
  .product-web-left-img img {
    height: 23rem;
  }
  .quantity-parent-prdct {
    width: 40%;
  }
  .cust-modal-heading-web h3 {
    font-size: 24px;
  }
  .img-modal-custom-header img {
    min-width: 70px;
    max-width: 70px;
    border-radius: 6px;
    height: 6rem;
  }
  .custom-web-modal-header {
    gap: 10px;
  }
  .modal-product-website .modal-dialog {
    max-width: 80%;
  }
  .related-pr-resp-img {
    width: 100%;
  }
  .add-to-cart-tab-btn button,
  .add-to-cart-tab-btn a {
    min-width: 137px;
    min-height: 33px;
    font-size: 15px;
    border-radius: 15px;
  }
  .btn-wrapper-filter {
    flex-direction: column;
  }
  .btn-wrapper-filter {
    position: relative;
  }
  .star-boxes-parent {
    position: absolute;
    top: 2rem;
    padding-top: 0.5rem;
  }
  .gived-reviews-parent {
    padding-top: 2.5rem;
  }
  .star-box {
    padding: 0.4rem 0.4rem;
  }
  .star-box svg {
    font-size: 11px;
  }
  .review-sec-heading h2 {
    font-size: 18px;
    padding-bottom: 0.45rem;
  }
  .cart-product-description img {
    min-width: 47px;
    max-width: 47px;
  }
  .grid-cart-inner p {
    font-size: 17px;
  }
  .cart-resuse-text {
    font-size: 16px;
  }
  .container-custom-cart {
    width: 88%;
  }
  .cart-btn-parent a {
    font-size: 10px;
    gap: 5px;
    min-width: 145px;
    min-height: 31px;
  }
  .checkout-content-parent {
    width: 95%;
  }
  .checkout-inner label sup {
    font-size: 8px;
  }
  .order-sum-heading h2 {
    font-size: 21px;
  }
  .thank-banner .banner-para {
    width: 75%;
  }
  .img-sec-three-ind img {
    height: 380px;
  }
  .copy-right-parent {
    gap: 0.8rem;
  }
  .copy-right-parent2 {
    width: 93%;
  }
  body {
    overflow-x: hidden;
  }
  .login-box {
    width: 90% !important;
  }
  .login-head-para h3 {
    font-size: 20px;
  }
  .login-head-para p {
    font-size: 13px;
  }
  .login-box-inner {
    width: 80%;
  }
}

@media only screen and (max-width: 385px) {
  .container-custom-cart {
    width: 92%;
  }
  .cart-resuse-text {
    font-size: 14px;
  }
  .input-add-prdct {
    width: 60%;
  }
  .copy-right-parent {
    min-width: 19rem;
  }
  .copy-right-parent {
    min-height: 37px;
  }
  .banner-txt-parent {
    padding-top: 8rem;
  }
}
